import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { TagsWithInputBox, TextBox, constants } from '@armis/armis-ui-library';
import { FormLabel, InputAdornment, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { IoIosClose } from 'react-icons/io';
import {
    ADD_TAGS_PLACEHOLDER,
    CREATE_TAG_INFO,
    REQUIRED,
    TAGS,
    TEMPLATE_NAME,
    TEMPLATE_PLACEHOLDER
} from 'src/constants/LabelText';
import { displayErrorMessage } from 'src/helpers/utility';
import { StyledActionContainer } from 'src/pages/containers/TenantView/Policies/Policies.style';
import { getTags } from 'src/services/api.service';

const readonlyTags = ['AMP Managed'];

interface PromotePolicyProps {
    policyName: string;
    selectedTags: string[];
    defaultTags?: string[];
    textInputDisabled?: boolean;
    icon?: JSX.Element | null;
    onNameChange?: (newValue: string) => void;
    setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
}

export const PromotePolicy = ({
    policyName,
    selectedTags,
    defaultTags = readonlyTags,
    textInputDisabled = true,
    icon = null,
    setSelectedTags,
    onNameChange
}: PromotePolicyProps) => {
    const [tags, setTags] = useState<string[]>([]);
    const [isErrorInName, setIsErrorInName] = useState(false);

    useEffect(() => {
        setIsErrorInName(!textInputDisabled && policyName.trim().length === 0);
    }, [policyName]);

    useEffect(() => {
        setSelectedTags([...defaultTags]);
    }, []);

    const getSearchedTags = useCallback(
        (tagSearch: string) => {
            getTags(tagSearch)
                .then(res => {
                    setTags(
                        res.data.content
                            .map((tag: any) => tag.name)
                            .filter(
                                (tag: string) => !selectedTags.includes(tag)
                            )
                    );
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        },
        [selectedTags]
    );

    const onTagsSearchInputChangeHandler = useMemo(
        () =>
            debounce((searchValue: string) => {
                getSearchedTags(searchValue);
            }, 500),
        [getSearchedTags]
    );

    return (
        <StyledActionContainer>
            <div className="policy-template-segment">
                <div className="header">
                    <FormLabel className="title" htmlFor="template-name-input">
                        <div className="icon-title-separator">
                            {icon}
                            <span className="title-name"> {TEMPLATE_NAME}</span>
                        </div>
                    </FormLabel>
                </div>
                <TextBox
                    className="content"
                    disabled={textInputDisabled}
                    error={isErrorInName}
                    helperText={isErrorInName && REQUIRED}
                    id="template-name-input"
                    InputProps={{
                        endAdornment: !isErrorInName && (
                            <InputAdornment
                                className="clear"
                                onClick={() => onNameChange?.('')}
                                position="end"
                                variant="outlined"
                            >
                                <IoIosClose />
                            </InputAdornment>
                        )
                    }}
                    onChange={e => onNameChange?.(e.target.value)}
                    placeholder={TEMPLATE_PLACEHOLDER}
                    sx={{
                        width: '100%'
                    }}
                    value={policyName}
                />
            </div>
            <div className="policy-template-segment">
                <div className="header">
                    <FormLabel className="title" htmlFor="template-tags-input">
                        <div className="icon-title-separator">
                            {icon}
                            <span className="title-name"> {TAGS}</span>
                        </div>
                    </FormLabel>
                </div>
                <TagsWithInputBox
                    className="content"
                    componentsProps={{
                        popper: {
                            sx: { zIndex: 10002 }
                        }
                    }}
                    data-testid="tags-autocomplete"
                    defaultValue={defaultTags}
                    id="template-tags-input"
                    onInputChange={(_, value) =>
                        value.trim().length &&
                        onTagsSearchInputChangeHandler(value)
                    }
                    onTagsChangeEmit={(value: string[]) => {
                        setSelectedTags(value);
                        setTags([]);
                    }}
                    options={tags}
                    placeholder={ADD_TAGS_PLACEHOLDER}
                    readOnlyOptions={readonlyTags}
                />
                <Typography
                    className="content"
                    sx={{ color: constants.COLOR_1 }}
                    variant="h6"
                >
                    {CREATE_TAG_INFO}
                </Typography>
            </div>
        </StyledActionContainer>
    );
};
