import React, { useState, useMemo, useEffect, useCallback } from 'react';

import {
    Back,
    Button,
    CheckBoxEmpty,
    CheckBoxFill,
    constants,
    Switch
} from '@armis/armis-ui-library';
import Editor, { OnMount } from '@monaco-editor/react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Typography,
    useTheme
} from '@mui/material';
import { cloneDeep, debounce, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    CANCEL,
    DELETE,
    DELETE_POLICIES,
    DELETE_POLICIES_WARNING,
    DELETE_POLICY,
    DELETE_POLICY_FROM_TENANT,
    DELETE_POLICIES_FROM_TENANT,
    DELETE_POLICY_WARNING,
    POLICIES_DELETED_SUCCESS,
    POLICY_DELETED_SUCCESS,
    SAVE,
    POLICY_TEMPLATE_UPDATE_SUCCESS,
    ADVANCED_JSON_EDIT,
    POLICY_TEMPLATE_JSON_EDIT_INFO,
    CONTINUE,
    EDIT_RESET_WARNING_MSG,
    CONFIRM_DISCARD_CHANGES,
    PUSH_POLICY,
    CONFIRM_ACTION,
    CONFIRM_PUSH,
    CONFIRM_PUSH_WARNING,
    PUSH_TEMPLATE_FEEDBACK_MSG,
    ENABLE_POLICIES_IN_TENANT,
    BACK
} from 'src/constants/LabelText';
import { FlexRow } from 'src/helpers/Common.style';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import { Modal } from 'src/pages/components/Modal';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { StyledText } from 'src/pages/components/WarningModalContainer/WarningModalContainer.style';
import { PolicyTemplateEdit } from 'src/pages/containers/PolicyTemplate/Edit/PolicyTemplateEdit';
import PolicyTemplatePushWizard from 'src/pages/containers/PolicyTemplate/Push/PolicyTemplatePushWizard';
import { Tenant } from 'src/pages/containers/TenantManagement/TenantManagement.types';
import {
    deletePolicyTemplate,
    pushTemplates,
    updatePolicyTemplate,
    validatePush
} from 'src/services/api.service';
import {
    selectIsPolicyTemplatesEnabled,
    selectSelectedTab,
    selectSelectedTenants,
    selectValidateTenantList,
    setIsPolicyTemplatesEnabled,
    setSelectedTab,
    setSelectedTenants,
    setTenants,
    setTotalRows,
    setValidateTenantList
} from 'src/store/slices/pushWizardStepSlice';
import { PushPoliciesPayload } from 'src/types/APIPayloadTypes';

import {
    allowedPushWizardTab,
    AvailableTemplatePolicyActions
} from './constants';
import { PolicyTemplateActionModalProps } from './PolicyTemplate.types';

export const PolicyTemplateActionModal = ({
    modalOpen,
    onClose,
    onSuccess,
    selectedAction,
    selectedPolicies,
    setIsLoading
}: PolicyTemplateActionModalProps) => {
    const [deletePoliciesFromTenant, setDeletePoliciesFromTenant] =
        useState(false);
    const [submitBtnLabel, setSubmitBtnLabel] = useState('');
    const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState(true);
    const [isAdvancedJsonEditEnabled, setisAdvancedJsonEditEnabled] =
        useState(false);
    const [showEditResetWarning, setshowEditResetWarning] = useState(false);
    const [policyToEdit, setpolicyToEdit] = useState(selectedPolicies[0]);

    const pushWizardSelectedTenants = useSelector(selectSelectedTenants);
    const pushWizardSelectedTab = useSelector(selectSelectedTab);
    const tenantsWithTemplateToPush = useSelector(selectValidateTenantList);
    const isPolicyTemplatesEnabled = useSelector(
        selectIsPolicyTemplatesEnabled
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedAction === AvailableTemplatePolicyActions.PUSH_POLICY) {
            if (
                pushWizardSelectedTenants.length === 0 &&
                pushWizardSelectedTab === allowedPushWizardTab.SELECT_TENANT
            ) {
                setIsDisabledSubmitBtn(true);
            } else {
                setIsDisabledSubmitBtn(false);
            }
        }
    }, [pushWizardSelectedTenants, pushWizardSelectedTab]);

    useEffect(() => {
        let noOneIsSelected = true;
        tenantsWithTemplateToPush.forEach(tenant => {
            tenant.templates?.forEach(pTemplate => {
                if (pTemplate.isSelected) {
                    noOneIsSelected = false;
                }
            });
        });

        setIsDisabledSubmitBtn(noOneIsSelected);
    }, [tenantsWithTemplateToPush]);

    // Component will get always single selected policy, so using index to fetch that policy.
    const getModalTitle = () => {
        switch (selectedAction) {
            case AvailableTemplatePolicyActions.DELETE:
                return selectedPolicies.length === 1
                    ? DELETE_POLICY
                    : DELETE_POLICIES;
            case AvailableTemplatePolicyActions.PUSH_POLICY:
                return PUSH_POLICY;
            default:
                return selectedPolicies[0].name;
        }
    };

    const resetPushWizardState = () => {
        dispatch(setSelectedTab(allowedPushWizardTab.SELECT_TENANT));
        dispatch(setSelectedTenants([]));
        dispatch(setTenants([]));
        dispatch(setValidateTenantList([]));
        dispatch(setTotalRows(null));
    };

    const validatePolicyTemplatePush = useCallback(() => {
        setIsLoading(true);
        validatePush({
            tenantIds: pushWizardSelectedTenants,
            policyTemplateIds: selectedPolicies.map(policy => policy.id)
        })
            .then(res => {
                const { tenants } = res.data;
                tenants.forEach((tenant: Tenant) => {
                    tenant.templates?.forEach(template => {
                        if (template.conflictStatus === 'NO_CONFLICT') {
                            template.isSelected = true;
                        } else {
                            template.isSelected = false;
                        }
                    });
                });

                dispatch(setValidateTenantList(tenants));
                dispatch(setSelectedTab(allowedPushWizardTab.VALIDATE_PUSH));
            })
            .catch(err => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [pushWizardSelectedTenants]);

    const onSubmitAction = () => {
        if (selectedAction === AvailableTemplatePolicyActions.DELETE) {
            setIsLoading(true);
            deletePolicyTemplate({
                policyTemplateIds: selectedPolicies.map(policy => policy.id),
                deleteTenantPolicy: deletePoliciesFromTenant
            })
                .then(() => {
                    showToast(
                        selectedPolicies.length === 1
                            ? POLICY_DELETED_SUCCESS
                            : POLICIES_DELETED_SUCCESS,
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                    onSuccess();
                })
                .catch(err => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (selectedAction === AvailableTemplatePolicyActions.EDIT) {
            setIsLoading(true);
            updatePolicyTemplate(policyToEdit.id, policyToEdit)
                .then(() => {
                    showToast(
                        POLICY_TEMPLATE_UPDATE_SUCCESS,
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                    onSuccess();
                })
                .catch(err => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (
            selectedAction === AvailableTemplatePolicyActions.PUSH_POLICY
        ) {
            if (pushWizardSelectedTab === allowedPushWizardTab.SELECT_TENANT) {
                validatePolicyTemplatePush();
            } else if (
                pushWizardSelectedTab === allowedPushWizardTab.VALIDATE_PUSH
            ) {
                setIsLoading(true);
                const data: PushPoliciesPayload = {
                    tenants: tenantsWithTemplateToPush
                        .map(tenant => ({
                            ...tenant,
                            templates: tenant.templates?.filter(
                                template => template.isSelected
                            )
                        }))
                        .filter(tenant => tenant.templates?.length) as Tenant[],
                    enable: isPolicyTemplatesEnabled
                };
                pushTemplates(data)
                    .then(() => {
                        showToast(
                            PUSH_TEMPLATE_FEEDBACK_MSG,
                            TOAST_TYPE.INFO,
                            TOAST_ID
                        );
                        onSuccess();
                        resetPushWizardState();
                    })
                    .catch(err => {
                        displayErrorMessage(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    };

    const onEditorMount: OnMount = editor => {
        editor.onDidChangeModelContent(
            debounce(() => {
                try {
                    const updatedPolicyJson = JSON.parse(editor.getValue());
                    setIsDisabledSubmitBtn(
                        isEqual(
                            selectedPolicies[0].policyJson,
                            updatedPolicyJson
                        )
                    );
                    setpolicyToEdit(prevValue => ({
                        ...prevValue,
                        policyJson: updatedPolicyJson
                    }));
                } catch {
                    setIsDisabledSubmitBtn(true);
                }
            }, 500)
        );
    };

    const isPolicyTemplateJsonSame = () => {
        const areEqual =
            isEqual(policyToEdit.policyJson, selectedPolicies[0].policyJson) &&
            policyToEdit.tags.length === selectedPolicies[0].tags.length &&
            policyToEdit.tags.every(tag =>
                selectedPolicies[0].tags.includes(tag)
            );
        return areEqual;
    };

    const theme = useTheme();

    const renderModalConent = useMemo(() => {
        let content: JSX.Element | string = '';
        switch (selectedAction) {
            case AvailableTemplatePolicyActions.DELETE:
                setSubmitBtnLabel(DELETE);
                setIsDisabledSubmitBtn(false);
                content = (
                    <WarningModalContainer
                        text={
                            selectedPolicies.length === 1
                                ? DELETE_POLICY_WARNING.replace(
                                      '%s',
                                      selectedPolicies[0].name
                                  )
                                : DELETE_POLICIES_WARNING.replace(
                                      '%s',
                                      selectedPolicies.length.toString()
                                  )
                        }
                    >
                        <FlexRow>
                            <Checkbox
                                checked={deletePoliciesFromTenant}
                                checkedIcon={<CheckBoxFill />}
                                icon={<CheckBoxEmpty />}
                                id="delete-policy-from-tenant"
                                onChange={({ target: { checked } }) => {
                                    setDeletePoliciesFromTenant(checked);
                                }}
                                onClick={e => e.stopPropagation()}
                                sx={{
                                    paddingLeft: '0px !important'
                                }}
                            />
                            <FormLabel htmlFor="delete-policy-from-tenant">
                                <StyledText>
                                    {selectedPolicies.length === 1
                                        ? DELETE_POLICY_FROM_TENANT
                                        : DELETE_POLICIES_FROM_TENANT}
                                </StyledText>
                            </FormLabel>
                        </FlexRow>
                    </WarningModalContainer>
                );
                break;
            case AvailableTemplatePolicyActions.EDIT:
                setSubmitBtnLabel(SAVE);
                content = (
                    <>
                        <Box
                            sx={{
                                justifyContent: 'flex-end',
                                display: 'flex'
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isAdvancedJsonEditEnabled}
                                        onChange={e =>
                                            isPolicyTemplateJsonSame() &&
                                            setisAdvancedJsonEditEnabled(
                                                e.target.checked
                                            )
                                        }
                                    />
                                }
                                label={ADVANCED_JSON_EDIT}
                                onClick={() =>
                                    !isPolicyTemplateJsonSame() &&
                                    setshowEditResetWarning(true)
                                }
                                sx={{
                                    '.MuiTypography-root': {
                                        fontWeight: 'bold'
                                    },
                                    '.MuiSwitch-root': {
                                        marginRight: 1
                                    }
                                }}
                            />
                        </Box>
                        {isAdvancedJsonEditEnabled ? (
                            <Box
                                sx={{
                                    paddingTop: '40px'
                                }}
                            >
                                <Typography
                                    className="content"
                                    sx={{
                                        color: constants.COLOR_1,
                                        marginBottom: 1
                                    }}
                                    variant="h5"
                                >
                                    {POLICY_TEMPLATE_JSON_EDIT_INFO}
                                </Typography>
                                <Editor
                                    defaultLanguage="json"
                                    defaultValue={JSON.stringify(
                                        selectedPolicies[0]?.policyJson,
                                        null,
                                        2
                                    )}
                                    height="50vh"
                                    onMount={onEditorMount}
                                    // theme="vs-dark"
                                    theme={
                                        theme.palette.mode === 'dark'
                                            ? 'vs-dark'
                                            : ''
                                    }
                                />
                            </Box>
                        ) : (
                            <PolicyTemplateEdit
                                onPolicyTemplateChanged={updatedPolicyTemplate => {
                                    let isDisabled =
                                        updatedPolicyTemplate.templateName?.trim()
                                            .length === 0;
                                    isDisabled =
                                        isDisabled ||
                                        !updatedPolicyTemplate.isValidRules;

                                    const removedExtraProp = cloneDeep(
                                        updatedPolicyTemplate
                                    );
                                    delete removedExtraProp.isValidRules;

                                    setIsDisabledSubmitBtn(
                                        isDisabled ||
                                            isEqual(
                                                selectedPolicies[0],
                                                removedExtraProp
                                            )
                                    );
                                    setpolicyToEdit(updatedPolicyTemplate);
                                }}
                                selectedPolicyTemplate={selectedPolicies[0]}
                            />
                        )}
                    </>
                );
                break;
            case AvailableTemplatePolicyActions.VIEW_JSON:
                setSubmitBtnLabel(CANCEL);
                content = (
                    <Editor
                        defaultLanguage="json"
                        defaultValue={JSON.stringify(
                            selectedPolicies[0]?.policyJson,
                            null,
                            2
                        )}
                        height="50vh"
                        options={{
                            readOnly: true
                        }}
                        theme={theme.palette.mode === 'dark' ? 'vs-dark' : ''}
                    />
                );
                break;
            case AvailableTemplatePolicyActions.PUSH_POLICY:
                content = (
                    <PolicyTemplatePushWizard
                        setSubmitBtnLabel={setSubmitBtnLabel}
                        validatePolicyTemplatePush={validatePolicyTemplatePush}
                    />
                );
                break;
            default:
                break;
        }
        return content;
    }, [
        selectedAction,
        selectedPolicies,
        deletePoliciesFromTenant,
        isAdvancedJsonEditEnabled,
        isDisabledSubmitBtn,
        policyToEdit.policyJson,
        submitBtnLabel,
        validatePolicyTemplatePush
    ]);

    const getConfirmModalTitle = () => {
        switch (selectedAction) {
            case AvailableTemplatePolicyActions.EDIT:
                return CONFIRM_DISCARD_CHANGES;
            case AvailableTemplatePolicyActions.PUSH_POLICY:
                return CONFIRM_PUSH;
            default:
                return CONFIRM_ACTION;
        }
    };

    const getModalClassName = () => {
        switch (selectedAction) {
            case AvailableTemplatePolicyActions.DELETE:
                return 'large';
            case AvailableTemplatePolicyActions.PUSH_POLICY:
                return 'xl-wide';
            default:
                return 'x-wide';
        }
    };

    return (
        <>
            {showEditResetWarning && (
                <Modal
                    displayBtn="all"
                    isModalOpen={showEditResetWarning}
                    onCancel={() => setshowEditResetWarning(false)}
                    onSubmit={() => {
                        setshowEditResetWarning(false);
                        if (
                            selectedAction ===
                            AvailableTemplatePolicyActions.PUSH_POLICY
                        ) {
                            onSubmitAction();
                            return;
                        }
                        setisAdvancedJsonEditEnabled(prevValue => !prevValue);
                        setpolicyToEdit(selectedPolicies[0]);
                        setIsDisabledSubmitBtn(true);
                    }}
                    submitBtnLabel={CONTINUE}
                    title={getConfirmModalTitle()}
                >
                    <WarningModalContainer
                        text={
                            selectedAction ===
                            AvailableTemplatePolicyActions.EDIT
                                ? EDIT_RESET_WARNING_MSG
                                : CONFIRM_PUSH_WARNING
                        }
                    >
                        {selectedAction ===
                        AvailableTemplatePolicyActions.PUSH_POLICY ? (
                            <FlexRow>
                                <Checkbox
                                    checked={isPolicyTemplatesEnabled}
                                    checkedIcon={<CheckBoxFill />}
                                    icon={<CheckBoxEmpty />}
                                    id="enabled-policy-to-tenant"
                                    onChange={({ target: { checked } }) => {
                                        dispatch(
                                            setIsPolicyTemplatesEnabled(checked)
                                        );
                                    }}
                                    sx={{
                                        paddingLeft: '0px !important',
                                        paddingTop: '6px !important',
                                        alignSelf: 'flex-start'
                                    }}
                                />
                                <FormLabel htmlFor="enabled-policy-to-tenant">
                                    <StyledText style={{ lineHeight: '20px' }}>
                                        {ENABLE_POLICIES_IN_TENANT}
                                    </StyledText>
                                </FormLabel>
                            </FlexRow>
                        ) : undefined}
                    </WarningModalContainer>
                </Modal>
            )}
            <Modal
                className={getModalClassName()}
                dialogActionsButton={
                    selectedAction ===
                        AvailableTemplatePolicyActions.PUSH_POLICY &&
                    pushWizardSelectedTab ===
                        allowedPushWizardTab.VALIDATE_PUSH ? (
                        <Button
                            className="control svg-symbol-button transition theme-5"
                            color="primary"
                            onClick={() => {
                                dispatch(
                                    setSelectedTab(
                                        allowedPushWizardTab.SELECT_TENANT
                                    )
                                );
                            }}
                            size="large"
                            startIcon={<Back />}
                            style={{
                                maxWidth: '100px',
                                minWidth: '100px',
                                padding: '8px',
                                fontWeight: 'bolder'
                            }}
                            variant="text"
                        >
                            {BACK}
                        </Button>
                    ) : null
                }
                displayBtn={
                    selectedAction === AvailableTemplatePolicyActions.VIEW_JSON
                        ? 'cancel'
                        : 'all'
                }
                isDisabledSubmitBtn={isDisabledSubmitBtn}
                isModalOpen={modalOpen}
                onCancel={() => {
                    resetPushWizardState();
                    onClose();
                }}
                onSubmit={() => {
                    if (
                        selectedAction ===
                            AvailableTemplatePolicyActions.PUSH_POLICY &&
                        pushWizardSelectedTab ===
                            allowedPushWizardTab.VALIDATE_PUSH
                    ) {
                        setshowEditResetWarning(true);
                    } else {
                        onSubmitAction();
                    }
                }}
                submitBtnLabel={submitBtnLabel}
                title={getModalTitle()}
            >
                {renderModalConent}
            </Modal>
        </>
    );
};
