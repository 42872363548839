import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
    StyledMenuPaper,
    IconButton,
    CustomHeader,
    Pagination,
    Tooltip,
    MultiSelect,
    Catalog,
    Sync,
    TableIcon,
    ShowSkeleton,
    TableSkeleton,
    TableNoData,
    Select,
    constants
} from '@armis/armis-ui-library';
import {
    Box,
    Menu,
    PaperProps,
    Typography,
    SelectChangeEvent
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ExpandScreen } from 'src/assets/icons/ExpandScreen';
import { TOAST_ID } from 'src/constants/APIConstants';
import { SYNC_STATUS } from 'src/constants/APIResponse';
import {
    AGGREGATED_VIEW,
    SYNC_MODAL_TITLE,
    SYNC_TENANTS_WARNING,
    TENANTS,
    SYNC,
    AGGREGATED_TENANTS_SEARCH,
    TENANT,
    COLON,
    LAST_SYNC,
    MINUTES,
    TABLE_VIEW,
    TILE_VIEW,
    SORT,
    CRITICALITY_LABEL,
    OFFLINE_COLLECTORS_LABEL,
    ERROR_INTEGRATIONS_LABEL,
    ALERTS_LABEL,
    A_TO_Z,
    OFFLINE_COLLECTORS_API_LABEL,
    ERROR_INTEGRATIONS_API_LABEL,
    ALERTS_API_LABEL,
    TENANT_NAME_API_LABEL,
    DESC,
    EXPAND_TILE_VIEW,
    INACTIVE_INTEGRATION,
    PENDING_ACTIVATION_COLLECTOR,
    DRAFT_INTEGRATION,
    INITIALIZING_COLLECTOR,
    SYNCED_SUCCESS,
    ASC,
    MAXIMIZE_SCREEN,
    TENANTS_SYNCED_INPROGRESS
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    aggregatedAPIMapping,
    NO_DATA_TO_SHOW,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    DEFAULT_QUERY_PROPERTIES,
    TILE_VIEW_DEFAULT_PAGESIZE,
    DEFAULT_PAGE_SIZE_ARRAY,
    DEFAULT_TILE_PAGE_SIZE_ARRAY
} from 'src/constants/TableConstants';
import { aggregatedColumnDef } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import { useTable } from 'src/hooks/useTable';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import {
    AggregatedData,
    TenantObj,
    ThresoldData,
    TileData
} from 'src/pages/containers/AggregatedView/AggregatedView.types';
import { TileViewComp } from 'src/pages/containers/TileView/TileView';
import {
    getAggregatedData,
    getAggregatedViewSyncStatus,
    getThresoldData,
    postPin,
    syncAggregatedView
} from 'src/services/api.service';
import { axiosController } from 'src/services/axiosInstance';
import { selectUser } from 'src/store/slices/userSlice';
import {
    ErrorResponse,
    SyncAggregationGetResponse,
    SyncAggregationPostResponse
} from 'src/types/APIResponseTypes';
import { Map, ChildRefProp, FilterItems } from 'src/types/CommonTypes';

import { StyledMenuItem, StyledTable } from './AggregatedView.style';

const aggregatedSortOrder: Map<number> = {};
const aggregatedSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const hideColumns = [
    INACTIVE_INTEGRATION,
    DRAFT_INTEGRATION,
    INITIALIZING_COLLECTOR,
    PENDING_ACTIVATION_COLLECTOR
];
createRelatedObject(
    aggregatedAPIMapping,
    aggregatedSortOrder,
    aggregatedSortStatus,
    columnsFilterItems,
    hideColumns
);

const tileViewSortingItems = [
    {
        name: CRITICALITY_LABEL
    },
    {
        name: OFFLINE_COLLECTORS_LABEL,
        apiKey: OFFLINE_COLLECTORS_API_LABEL
    },
    {
        name: ERROR_INTEGRATIONS_LABEL,
        apiKey: ERROR_INTEGRATIONS_API_LABEL
    },
    {
        name: ALERTS_LABEL,
        apiKey: ALERTS_API_LABEL
    },
    {
        name: A_TO_Z,
        apiKey: TENANT_NAME_API_LABEL
    }
];

export const AggregatedView = () => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        modelOpen,
        setModelOpen,
        anchorEl,
        onSelectionChanged,
        queryProperties,
        setQueryProperties,
        setColumnSortOrder,
        setColumnSortStatus
    } = useTable({
        sortOrderObj: aggregatedSortOrder,
        sortStatusObj: aggregatedSortStatus,
        columnsFilterItems
    });

    const theme = useTheme();

    const [thresoldData, setThresoldData] = useState<ThresoldData>();
    const [intervalvalue, setIntervalValue] = useState<number | null>(null);
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);
    const [catalog, setCatalog] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [rowData, setRowData] = useState<AggregatedData[] | null | TileData>(
        null
    );
    const [pageSizeArray, setPageSizeArray] = useState(DEFAULT_PAGE_SIZE_ARRAY);
    const [refreshPage, setRefreshPage] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const [lastSynced, setlastSynced] = useState(0);
    const [dropDownValue, setDropDownValue] = useState(0);

    const currentUser = useSelector(selectUser);
    const isArmisUser = currentUser.username.includes('@armis.com');

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                thresoldData,
                columnSortStatus
            },
            sortable: true,
            resizable: true
        }),
        [columnSortOrder, columnSortStatus, thresoldData]
    );

    const pollIntervalId = useRef<any>(0);

    const filterApiData = React.useCallback(
        (element: AggregatedData, thresoldObject: ThresoldData) => {
            const { tenant, ...rest } = element;
            let criticality = 0;
            if (
                element.offlineCollector >=
                    thresoldObject!.COLLECTORS_OFFLINE_THRESHOLD ||
                element.alert >= thresoldObject!.ALERT_THRESHOLD ||
                element.errorIntegration >=
                    thresoldObject!.INTEGRATION_ERROR_THRESHOLD
            ) {
                criticality = 2;
            } else if (
                element.offlineCollector > 0 ||
                element.alert > 0 ||
                element.errorIntegration > 0
            ) {
                criticality = 1;
            }
            return {
                ...rest,
                criticality,
                tenant: {
                    id: tenant.id,
                    pinOrder: tenant.pinOrder
                },
                tenantName: tenant.name,
                tenantUrl: tenant.tenantUrl
            } as AggregatedData;
        },
        []
    );

    const resetStateAfterSync = (resetLoading: boolean = false) => {
        setTableLoading(resetLoading);
        setIsSyncing(false);
        clearTimeout(pollIntervalId.current);
        pollIntervalId.current = 0;
    };

    const pollAggregationSyncStatus = () => {
        getAggregatedViewSyncStatus()
            .then((res: AxiosResponse<SyncAggregationGetResponse>) => {
                const { status, message } = res.data;
                if (status === SYNC_STATUS.SUCCESS) {
                    showToast(SYNCED_SUCCESS, TOAST_TYPE.SUCCESS, TOAST_ID);
                    setRefreshPage(prevValue => !prevValue);
                    resetStateAfterSync(true);
                } else if (status === SYNC_STATUS.FAILED) {
                    showToast(message, TOAST_TYPE.ERROR, TOAST_ID);
                    setRefreshPage(prevValue => !prevValue);
                    resetStateAfterSync(true);
                } else if (status === SYNC_STATUS.IN_PROGRESS) {
                    pollIntervalId.current = setTimeout(() => {
                        pollAggregationSyncStatus();
                    }, 1500);
                }
            })
            .catch(err => {
                displayErrorMessage(err);
                resetStateAfterSync();
            });
    };

    const syncAggregatedData = async () => {
        setTableLoading(true);
        setIsSyncing(true);
        syncAggregatedView()
            .then((res: AxiosResponse<SyncAggregationPostResponse>) => {
                if (!res.data.syncInProgress) {
                    pollAggregationSyncStatus();
                } else {
                    showToast(
                        TENANTS_SYNCED_INPROGRESS,
                        TOAST_TYPE.INFO,
                        TOAST_ID
                    );
                    resetStateAfterSync();
                }
            })
            .catch(err => {
                displayErrorMessage(err);
                resetStateAfterSync();
            });
    };

    const doThresoldAndTenantsCall = async (
        isRefresh: boolean = false,
        threSoldCall: boolean = false
    ) => {
        setTableLoading(true);
        try {
            const thresoldObject: any = {};
            if (threSoldCall || !thresoldData) {
                const { data } = await getThresoldData();
                data.forEach((element: any) => {
                    thresoldObject[element.name] = element.value;
                });
                setThresoldData(thresoldObject);
            }
            const actualThresoldData =
                Object.keys(thresoldObject).length === 0
                    ? thresoldData
                    : thresoldObject;
            const { data: data1 } = await getAggregatedData(
                convertQueryObjectToParams({ ...queryProperties, isRefresh }),
                catalog
            );

            const expiryTimeData = data1.expiryTime;
            const rowDataArray = data1.unPinned.content.map(
                (element: AggregatedData) =>
                    filterApiData(element, actualThresoldData)
            );
            if (catalog) {
                if (dropDownValue === 0) {
                    rowDataArray.sort(
                        (a: AggregatedData, b: AggregatedData) =>
                            b.criticality - a.criticality
                    );
                }
                const data2 = {
                    pinned: data1.pinned
                        .map((element: AggregatedData) =>
                            filterApiData(element, actualThresoldData)
                        )
                        .sort(
                            (a: AggregatedData, b: AggregatedData) =>
                                a.tenant.pinOrder - b.tenant.pinOrder
                        ),
                    unPinned: rowDataArray
                };
                setRowData(data2);
                setTotalRows(data1.unPinned.totalElements);
            } else {
                setRowData(rowDataArray);
                setTotalRows(data1.unPinned.totalElements);
            }
            setlastSynced(data1.lastSynced);
            if (expiryTimeData && expiryTimeData >= new Date().getTime()) {
                const interval = expiryTimeData - new Date().getTime();
                setIntervalValue(interval);
            } else {
                setIntervalValue(null);
            }
            if (isRefresh) {
                showToast(SYNCED_SUCCESS, TOAST_TYPE.SUCCESS, TOAST_ID);
            }
        } catch (err: any) {
            displayErrorMessage(err);
            if (catalog) {
                setRowData({
                    pinned: [],
                    unPinned: []
                });
            } else {
                setRowData([]);
            }
            setTotalRows(0);
        } finally {
            setTableLoading(false);
            if (
                queryProperties.page === DEFAULT_PAGE &&
                queryProperties.size ===
                    (catalog ? TILE_VIEW_DEFAULT_PAGESIZE : DEFAULT_PAGESIZE)
            ) {
                paginationRef.current?.resetPagination();
            }
        }
    };

    useEffect(() => {
        let intervalId: any;
        if (intervalvalue) {
            intervalId = setInterval(
                () => doThresoldAndTenantsCall(false, true),
                intervalvalue
            );
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalvalue]);

    useEffect(() => {
        if (firstRender.current) {
            return;
        }
        doThresoldAndTenantsCall(false, true);
    }, [refreshPage]);

    useEffect(
        () => () => {
            axiosController.controller.abort();
            clearTimeout(pollIntervalId.current);
        },
        []
    );

    useEffect(() => {
        if (firstRender.current) {
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    useEffect(() => {
        doThresoldAndTenantsCall(false, firstRender.current);
        if (firstRender.current) {
            firstRender.current = false;
        }
    }, [queryProperties]);

    const switchView = (catalogBool: boolean) => {
        if (catalog !== catalogBool) {
            setTableLoading(true);
            if (catalogBool) {
                setQueryProperties({
                    ...DEFAULT_QUERY_PROPERTIES,
                    size: TILE_VIEW_DEFAULT_PAGESIZE
                });
                setPageSizeArray(DEFAULT_TILE_PAGE_SIZE_ARRAY);
                setDropDownValue(0);
            } else {
                setPageSizeArray(DEFAULT_PAGE_SIZE_ARRAY);
                setQueryProperties({ ...DEFAULT_QUERY_PROPERTIES });
                setColumnSortOrder({ ...aggregatedSortOrder });
                setColumnSortStatus({ ...aggregatedSortStatus });
            }
            setCatalog(catalogBool);
            setRowData(null);
            firstRender.current = true;
        }
    };

    const selectValueChange = (e: SelectChangeEvent<unknown>) => {
        const optionNumber = e.target.value as number;
        let directions = '';
        let properties = '';
        if (
            ![CRITICALITY_LABEL, A_TO_Z].includes(
                tileViewSortingItems[optionNumber].name
            )
        ) {
            properties = tileViewSortingItems[optionNumber].apiKey!;
            directions = DESC;
        } else if (tileViewSortingItems[optionNumber].name === A_TO_Z) {
            directions = ASC;
            properties = tileViewSortingItems[optionNumber].apiKey!;
        }
        setDropDownValue(optionNumber);
        setQueryProperties({
            ...queryProperties,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: TILE_VIEW_DEFAULT_PAGESIZE
        });
    };

    const fullScreenView = () => {
        const elem: any = document.getElementById(EXPAND_TILE_VIEW);
        if (elem?.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem?.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem?.msRequestFullscreen) {
            /* IE11 */
            elem.msRequestFullscreen();
        }
    };

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (
            !catalog &&
            (rowData as AggregatedData[])?.length === 0 &&
            !tableLoading &&
            !firstRender.current
        ) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [rowData, tableLoading, gridRef]);

    const pinActionTile = (tenantArray: TenantObj[]) => {
        setTableLoading(true);
        postPin(tenantArray)
            .then(() => {
                doThresoldAndTenantsCall();
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
                setTableLoading(false);
            });
    };

    const gridReady = () => {
        hideColumns.forEach(element =>
            gridRef.current?.columnApi?.setColumnVisible(element, false)
        );
    };
    const totalRowsCountTitle = catalog
        ? totalRows + ((rowData as TileData)?.pinned?.length ?? 0)
        : totalRows;
    return (
        <>
            <Header title={AGGREGATED_VIEW} />
            <div className="control table">
                <TableHeader
                    childrenLeft={
                        <>
                            {!!lastSynced && (
                                <ShowSkeleton
                                    content={
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography
                                                sx={{
                                                    color:
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'white'
                                                            : constants.COLOR_GENERAL_3
                                                }}
                                                variant="h5"
                                            >
                                                {LAST_SYNC}
                                                {COLON}&nbsp;
                                            </Typography>
                                            <Typography variant="h5">
                                                {lastSynced > 0 &&
                                                    moment(lastSynced)
                                                        .startOf(MINUTES)
                                                        .fromNow()}
                                            </Typography>
                                        </Box>
                                    }
                                    loading={tableLoading}
                                />
                            )}
                            {catalog ? (
                                <Select
                                    disabled={tableLoading}
                                    MenuProps={{
                                        sx: { zIndex: 10002 }
                                    }}
                                    onChange={selectValueChange}
                                    renderValue={(value: unknown) => (
                                        <span>
                                            {`${SORT} ${
                                                tileViewSortingItems[
                                                    value as number
                                                ].name
                                            }`}
                                        </span>
                                    )}
                                    sx={{
                                        width: '160px'
                                    }}
                                    value={dropDownValue}
                                >
                                    {tileViewSortingItems.map(
                                        (element, index1) => (
                                            <StyledMenuItem
                                                key={index1}
                                                value={index1}
                                            >
                                                {element.name}
                                            </StyledMenuItem>
                                        )
                                    )}
                                </Select>
                            ) : (
                                <SearchBar
                                    disabled={tableLoading}
                                    onChange={newValue => {
                                        setQueryProperties(prevValue => ({
                                            ...prevValue,
                                            searchBy: newValue,
                                            page: DEFAULT_PAGE,
                                            size: DEFAULT_PAGESIZE
                                        }));
                                    }}
                                    placeholder={AGGREGATED_TENANTS_SEARCH}
                                    searchValue={
                                        queryProperties.searchBy as string
                                    }
                                />
                            )}
                        </>
                    }
                    childrenRight={
                        <>
                            <Tooltip
                                arrow
                                title={
                                    <div className="tooltip-arrow-text">
                                        {' '}
                                        {SYNC}{' '}
                                    </div>
                                }
                            >
                                <span>
                                    <IconButton
                                        aria-label={SYNC}
                                        className="Icon-Hover-Effect"
                                        disabled={tableLoading}
                                        onClick={() => setModelOpen(true)}
                                    >
                                        <Sync />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip
                                arrow
                                title={
                                    <div className="tooltip-arrow-text">
                                        {' '}
                                        {TILE_VIEW}{' '}
                                    </div>
                                }
                            >
                                <span>
                                    <IconButton
                                        aria-label={TILE_VIEW}
                                        className={
                                            !catalog
                                                ? 'Button-toggle Icon-Hover-Effect'
                                                : 'Icon-Hover-Effect'
                                        }
                                        disabled={tableLoading}
                                        onClick={() => switchView(true)}
                                    >
                                        <Catalog />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip
                                arrow
                                title={
                                    <div className="tooltip-arrow-text">
                                        {' '}
                                        {TABLE_VIEW}{' '}
                                    </div>
                                }
                            >
                                <span>
                                    <IconButton
                                        aria-label={TABLE_VIEW}
                                        className={
                                            catalog
                                                ? 'Button-toggle Icon-Hover-Effect'
                                                : 'Icon-Hover-Effect'
                                        }
                                        disabled={tableLoading}
                                        onClick={() => switchView(false)}
                                    >
                                        <TableIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {catalog && (
                                <Tooltip
                                    arrow
                                    title={
                                        <div className="tooltip-arrow-text">
                                            {' '}
                                            {MAXIMIZE_SCREEN}{' '}
                                        </div>
                                    }
                                >
                                    <span>
                                        <IconButton
                                            aria-label={MAXIMIZE_SCREEN}
                                            className="Icon-Hover-Effect"
                                            disabled={isSyncing}
                                            onClick={fullScreenView}
                                        >
                                            <ExpandScreen />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    }
                    hideColumnsFilter={catalog}
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    title={`${totalRowsCountTitle} ${
                        totalRowsCountTitle === 1 ? `${TENANT}` : `${TENANTS}`
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Modal
                    displayBtn="all"
                    isModalOpen={modelOpen}
                    onCancel={() => setModelOpen(false)}
                    onSubmit={() => {
                        setModelOpen(false);
                        syncAggregatedData();
                    }}
                    submitBtnLabel={SYNC}
                    title={SYNC_MODAL_TITLE}
                >
                    <WarningModalContainer text={SYNC_TENANTS_WARNING} />
                </Modal>
                {!catalog ? (
                    <StyledTable
                        ref={gridRef}
                        columnDefs={aggregatedColumnDef}
                        context={{ ...thresoldData, isArmisUser }}
                        defaultColDef={defaultColDefs}
                        headerHeight={35}
                        loadingOverlayComponent={TableSkeleton}
                        loadingOverlayComponentParams={{
                            style: { top: '70px' }
                        }}
                        noRowsOverlayComponent={TableNoData}
                        noRowsOverlayComponentParams={{
                            content: NO_DATA_TO_SHOW
                        }}
                        onGridReady={gridReady}
                        rowData={rowData as AggregatedData[] | null}
                        suppressNoRowsOverlay={firstRender.current}
                    />
                ) : (
                    <TileViewComp
                        isArmisUser={isArmisUser}
                        loading={tableLoading}
                        pinActionTile={pinActionTile}
                        rowData={rowData as TileData | null}
                        thresoldData={thresoldData!}
                    />
                )}
                {!!totalRows && totalRows > 0 && (
                    <span
                        style={{
                            display: isSyncing ? 'none' : ''
                        }}
                    >
                        <Pagination
                            ref={paginationRef}
                            onPaginationChanged={(page, size) => {
                                setQueryProperties(prevValue => ({
                                    ...prevValue,
                                    page,
                                    size
                                }));
                            }}
                            pageSizeArray={pageSizeArray}
                            totalRowsCount={totalRows}
                        />
                    </span>
                )}
            </div>
        </>
    );
};
