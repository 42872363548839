import React, { useMemo, useState, useEffect } from 'react';

import { Button } from '@armis/armis-ui-library';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AxiosError } from 'axios';
import { isEqual, cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import { FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    AGGREGATED_VIEW_SECTION_TITLE,
    CUSTOM_PROPERTIES_TITLE,
    AGGREGATED_VIEW_SECTION_PARAGRAPH,
    ALERT_THRESHOLD_VALUE,
    ALERT_ASQ,
    DEVICES_ASQ,
    INTEGRATION_ERROR_THRESHOLD,
    OFFLINE_COLLECTORS,
    AGGREGATED_SCHEDULER,
    SAVE,
    SET_TO_DEFAULT,
    TO_DEFAULT_PROPERTIES_WARNING,
    PROPERTIES_UPDATED_SUCCESS
} from 'src/constants/LabelText';
import {
    showToast,
    TOAST_TYPE,
    CRON_SCALES,
    sortPropertyObject,
    displayErrorMessage,
    validateValues,
    isFieldHavingError
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import {
    getThresoldData,
    updateCustomPropertiesData
} from 'src/services/api.service';
import {
    selectCustomPropertiesStructure,
    setCustomPropertiesStructure
} from 'src/store/slices/customPropertiesSlice';
import { CustomPropertiesPayload } from 'src/types/APIPayloadTypes';
import {
    CustomPropertiesResponse,
    CustomPropertyResponse,
    ErrorResponse
} from 'src/types/APIResponseTypes';
import { FieldValidationType } from 'src/types/CommonTypes';

import { CronEditField } from './CronEditField/CronEditField';
import {
    CustomPropertiesContainer,
    StyledSectionParagraph,
    Control,
    Area,
    FieldsContainer,
    ButtonGroup
} from './CustomProperties.style';
import { CustomPropertiesProps } from './CustomProperties.types';
import { EditField } from './EditField/EditField';

const PROPERTY_FIELD_KEY_MAP = {
    ALERT_THRESHOLD: 'ALERT_THRESHOLD',
    ALERT_ASQ: 'ALERT_ASQ',
    DEVICES_ASQ: 'DEVICES_ASQ',
    INTEGRATION_ERROR_THRESHOLD: 'INTEGRATION_ERROR_THRESHOLD',
    COLLECTORS_OFFLINE_THRESHOLD: 'COLLECTORS_OFFLINE_THRESHOLD',
    AGGREGATED_SCHEDULER: 'AGGREGATED_SCHEDULER'
};

const getMinValueByScale = (scale: string) =>
    scale === CRON_SCALES.SECONDS ? 2 : 1;
const getMaxValueByScale = (scale: string) =>
    scale === CRON_SCALES.HOURS ? 23 : 59;

const EditFieldMetaData = {
    [PROPERTY_FIELD_KEY_MAP.ALERT_THRESHOLD]: {
        label: ALERT_THRESHOLD_VALUE,
        key: PROPERTY_FIELD_KEY_MAP.ALERT_THRESHOLD,
        type: FIELD_TYPE_MAP.TEXT,
        validations: {
            minValue: 0,
            regex: '^[0-9]*$',
            required: true,
            maxLength: 1000
        } as FieldValidationType
    },
    [PROPERTY_FIELD_KEY_MAP.ALERT_ASQ]: {
        label: ALERT_ASQ,
        key: PROPERTY_FIELD_KEY_MAP.ALERT_ASQ,
        type: FIELD_TYPE_MAP.TEXT,
        validations: {
            required: true,
            maxLength: 1000
        } as FieldValidationType
    },
    [PROPERTY_FIELD_KEY_MAP.DEVICES_ASQ]: {
        label: DEVICES_ASQ,
        key: PROPERTY_FIELD_KEY_MAP.DEVICES_ASQ,
        type: FIELD_TYPE_MAP.TEXT,
        validations: {
            required: true,
            maxLength: 1000
        } as FieldValidationType
    },
    [PROPERTY_FIELD_KEY_MAP.INTEGRATION_ERROR_THRESHOLD]: {
        label: INTEGRATION_ERROR_THRESHOLD,
        key: PROPERTY_FIELD_KEY_MAP.INTEGRATION_ERROR_THRESHOLD,
        type: FIELD_TYPE_MAP.TEXT,
        validations: {
            minValue: 0,
            regex: '^[0-9]*$',
            required: true,
            maxLength: 1000
        } as FieldValidationType
    },
    [PROPERTY_FIELD_KEY_MAP.COLLECTORS_OFFLINE_THRESHOLD]: {
        label: OFFLINE_COLLECTORS,
        key: PROPERTY_FIELD_KEY_MAP.COLLECTORS_OFFLINE_THRESHOLD,
        type: FIELD_TYPE_MAP.TEXT,
        validations: {
            minValue: 0,
            regex: '^[0-9]*$',
            required: true,
            maxLength: 1000
        } as FieldValidationType
    },
    [PROPERTY_FIELD_KEY_MAP.AGGREGATED_SCHEDULER]: {
        label: AGGREGATED_SCHEDULER,
        key: PROPERTY_FIELD_KEY_MAP.AGGREGATED_SCHEDULER,
        type: FIELD_TYPE_MAP.SCHEDULER,
        validations: {
            scaleMinValue: (scale: string) => getMinValueByScale(scale),
            scaleMaxValue: (scale: string) => getMaxValueByScale(scale),
            required: true
        } as FieldValidationType
    }
};

export const CustomPropertiesComponent = ({
    setIsLoading
}: CustomPropertiesProps) => {
    const theme = useTheme();
    const storedCustomProperties = useSelector(selectCustomPropertiesStructure);
    const dispatch = useDispatch();
    const [customProperties, setCustomProperties] =
        useState<CustomPropertiesResponse>();
    const [customPropertiesErrors, setCustomPropertiesErrors] = useState<any>(
        {}
    );
    const [modelOpen, setModelOpen] = useState(false);
    const topHeadingSection = useMemo(
        () => (
            <>
                <div style={{ marginLeft: '15px' }}>
                    <Header title={AGGREGATED_VIEW_SECTION_TITLE} />
                </div>
                <StyledSectionParagraph>
                    {AGGREGATED_VIEW_SECTION_PARAGRAPH}
                </StyledSectionParagraph>
            </>
        ),
        []
    );

    useEffect(() => {
        setIsLoading(true);
        getThresoldData()
            .then(res => {
                const customPropertiesResponse =
                    res.data as CustomPropertyResponse[];
                dispatch(
                    setCustomPropertiesStructure(
                        cloneDeep(customPropertiesResponse)
                    )
                );
                setCustomProperties(cloneDeep(customPropertiesResponse));
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setCustomProperties([]);
                displayErrorMessage(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [selectCustomPropertiesStructure]);

    const fieldOnChangeHandler = (name: string, value: string) => {
        const currentValue = customProperties?.find(
            propertyObj => propertyObj.name === name
        );
        const otherObjCurrentValues = customProperties?.filter(
            propertyObj => propertyObj.name !== name
        );

        if (otherObjCurrentValues && currentValue) {
            setCustomProperties([
                ...(otherObjCurrentValues as CustomPropertyResponse[]),
                {
                    ...currentValue,
                    value
                }
            ]);
            const customPropertiesErrorsCopy = cloneDeep(
                customPropertiesErrors
            );
            delete customPropertiesErrorsCopy[name];
            setCustomPropertiesErrors(customPropertiesErrorsCopy);
        }
    };

    const getValue = (name: string) => {
        const currentValue = customProperties?.find(
            propertyObj => propertyObj.name === name
        );
        return currentValue?.value! || '';
    };

    const decodeSchedulerValue = (name: string) => {
        const currentValue = customProperties?.find(
            propertyObj => propertyObj.name === name
        );
        return currentValue?.value! || '';
    };

    const getErrorHelperText = (field: string) =>
        customPropertiesErrors?.[field]?.helperText ?? '';
    const compareOldAndUpdatedState = () => {
        const stringifyOldValue = JSON.stringify(
            cloneDeep(storedCustomProperties)?.sort(sortPropertyObject)
        );
        const stringifyNewValue = JSON.stringify(
            cloneDeep(customProperties)?.sort(sortPropertyObject)
        );
        return isEqual(stringifyOldValue, stringifyNewValue);
    };
    const compareDefaultAndCurrentValue = () => {
        const filteredProperties = storedCustomProperties?.filter(
            propertyObject =>
                propertyObject.defaultValue !== propertyObject.value
        );
        return filteredProperties?.length! > 0;
    };

    const updateCustomProperties = (updatedData: CustomPropertiesPayload) => {
        updateCustomPropertiesData(updatedData as CustomPropertiesPayload)
            .then(res => {
                const customPropertiesResponse =
                    res.data as CustomPropertyResponse[];
                dispatch(
                    setCustomPropertiesStructure(customPropertiesResponse)
                );
                setCustomProperties(customPropertiesResponse);
                showToast(
                    PROPERTIES_UPDATED_SUCCESS,
                    TOAST_TYPE.SUCCESS,
                    TOAST_ID
                );
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setCustomProperties([]);
                displayErrorMessage(err);
            })
            .finally(() => {
                setCustomPropertiesErrors({});
                setIsLoading(false);
            });
    };

    const validateAndSubmit = () => {
        let fieldValidationErrorArray = {};
        customProperties?.forEach(propertyObj => {
            if (EditFieldMetaData[propertyObj.name]) {
                const validationErrorObject = validateValues(
                    propertyObj.value,
                    EditFieldMetaData[propertyObj.name].validations,
                    EditFieldMetaData[propertyObj.name].type
                );
                if (validationErrorObject.error)
                    fieldValidationErrorArray = {
                        ...fieldValidationErrorArray,
                        [propertyObj.name]: {
                            ...validationErrorObject
                        }
                    };
            }
        });
        if (Object.keys(fieldValidationErrorArray).length > 0) {
            setCustomPropertiesErrors(fieldValidationErrorArray);
        } else {
            setIsLoading(true);
            updateCustomProperties(customProperties as CustomPropertiesPayload);
        }
    };

    const setDefaultProperties = () => {
        const newCustomPropertiesObject = cloneDeep(customProperties);
        newCustomPropertiesObject?.forEach(propertyObject => {
            if (propertyObject.defaultValue !== propertyObject.value)
                propertyObject.value = propertyObject.defaultValue;
        });
        setIsLoading(true);
        updateCustomProperties(
            newCustomPropertiesObject as CustomPropertiesPayload
        );
    };

    const getEditFields = () =>
        Object.keys(EditFieldMetaData).map(field => {
            if (EditFieldMetaData[field].type === FIELD_TYPE_MAP.SCHEDULER) {
                return (
                    <CronEditField
                        key={EditFieldMetaData[field].key}
                        error={isFieldHavingError(
                            field,
                            customPropertiesErrors
                        )}
                        id={EditFieldMetaData[field].key}
                        label={EditFieldMetaData[field].label}
                        onChange={value => {
                            fieldOnChangeHandler(
                                EditFieldMetaData[field].key,
                                value
                            );
                        }}
                        value={decodeSchedulerValue(
                            EditFieldMetaData[field].key
                        )}
                    />
                );
            }
            return (
                <EditField
                    key={EditFieldMetaData[field].key}
                    error={isFieldHavingError(field, customPropertiesErrors)}
                    helperText={getErrorHelperText(field)}
                    id={EditFieldMetaData[field].key}
                    label={EditFieldMetaData[field].label}
                    onChange={value => {
                        fieldOnChangeHandler(
                            EditFieldMetaData[field].key,
                            value
                        );
                    }}
                    showRemove={
                        EditFieldMetaData[field].type === FIELD_TYPE_MAP.TEXT
                    }
                    type={EditFieldMetaData[field].type}
                    value={getValue(EditFieldMetaData[field].key)}
                />
            );
        });

    return (
        <CustomPropertiesContainer>
            {topHeadingSection}
            <Modal
                displayBtn="all"
                isModalOpen={modelOpen}
                onCancel={() => setModelOpen(false)}
                onSubmit={() => {
                    setModelOpen(false);
                    setDefaultProperties();
                }}
                submitBtnLabel={SET_TO_DEFAULT}
                title={CUSTOM_PROPERTIES_TITLE}
            >
                <WarningModalContainer text={TO_DEFAULT_PROPERTIES_WARNING} />
            </Modal>
            <Control>
                <Area>
                    <FieldsContainer
                        sx={{
                            color: theme.palette.mode === 'dark' ? 'white' : ''
                        }}
                    >
                        <Grid
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            container
                            rowSpacing={1}
                        >
                            {getEditFields()}
                            <Grid item textAlign="right" xs={12}>
                                <ButtonGroup>
                                    <Button
                                        color="primary"
                                        disabled={
                                            !compareDefaultAndCurrentValue()
                                        }
                                        onClick={() => setModelOpen(true)}
                                        style={{ marginRight: '10px' }}
                                        sx={{ mr: '10px' }}
                                        variant="contained"
                                    >
                                        {SET_TO_DEFAULT}
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={compareOldAndUpdatedState()}
                                        onClick={validateAndSubmit}
                                        variant="contained"
                                    >
                                        {SAVE}
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </FieldsContainer>
                </Area>
            </Control>
        </CustomPropertiesContainer>
    );
};

export const CustomProperties = IsLoadingHOC(CustomPropertiesComponent);
