import { useEffect, useState, useRef, useMemo } from 'react';

import {
    CHECKBOX_STATUS,
    CustomHeader,
    IconButton,
    MultiSelect,
    Pagination,
    Refresh,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    Tooltip
} from '@armis/armis-ui-library';
import { Menu, PaperProps } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import {
    DETAILED_VIEW,
    PUSH,
    PUSH_HISTORY_TEXT,
    REFRESH
} from 'src/constants/LabelText';
import { PUSH_HISTORY, TEMPLATE_ROUTE } from 'src/constants/RouteConstants';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    pushHistoryAPIMapping
} from 'src/constants/TableConstants';
import { pushHistoryColumnsDef } from 'src/helpers/ColumnsConfig';
import { StyledLink } from 'src/helpers/Common.style';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { Header } from 'src/pages/components/Header';
import { TableHeader } from 'src/pages/components/TableHeader';
import {
    getAllPushHistory,
    getPushHistoryFilterColumns
} from 'src/services/api.service';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

const pushHistorySortOrder: Map<number> = {};
const pushHistorySortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const columnFilterObj: { [key: string]: FilterItems[] } = {
    createdby: [],
    status: []
};
createRelatedObject(
    pushHistoryAPIMapping,
    pushHistorySortOrder,
    pushHistorySortStatus,
    columnsFilterItems
);
const PushHistoryComponent = () => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        anchorEl,
        filterItems,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        columnFilterData,
        setColumnFilterData,
        onFilterChanged,
        queryProperties,
        setQueryProperties
    } = useTable({
        sortOrderObj: pushHistorySortOrder,
        sortStatusObj: pushHistorySortStatus,
        columnsFilterItems,
        columnFilterObj
    });

    const [rowData, setRowData] = useState<any>([]);
    const [totalRows, setTotalRows] = useState(0);
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                onFilterChanged: (
                    columnName: string,
                    selectedItems: FilterItems[]
                ) => {
                    onFilterChanged(columnName, selectedItems);
                    paginationRef.current?.resetPagination();
                },
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true,
            filter: false,
            filterParams: columnFilterData
        }),
        [columnSortOrder, onSortChangedCall, columnSortStatus]
    );

    const getPushHistory = async () => {
        setTableLoading(true);
        try {
            if (
                columnFilterData.createdby.length === 0 ||
                columnFilterData.status.length === 0
            ) {
                const columnData = await getPushHistoryFilterColumns();
                setColumnFilterData({
                    createdby: columnData.data.createdby.map((uname: any) => ({
                        id: uname,
                        label: uname,
                        labelRender: <span>{uname}</span>,
                        checkStatus: CHECKBOX_STATUS.CHECKED
                    })),
                    status: columnData.data.status.map((action: any) => ({
                        id: action,
                        label: action,
                        labelRender: <span>{action}</span>,
                        checkStatus: CHECKBOX_STATUS.CHECKED
                    }))
                });
            }
            const res = await getAllPushHistory(
                convertQueryObjectToParams(queryProperties)
            );
            setRowData(res.data.content);
            setTotalRows(res.data.totalElements);
        } catch (err: any) {
            displayErrorMessage(err);
            setRowData([]);
            setTotalRows(0);
        } finally {
            setTableLoading(false);
            if (
                queryProperties.page === DEFAULT_PAGE &&
                queryProperties.size === DEFAULT_PAGESIZE
            )
                paginationRef.current?.resetPagination();
        }
    };
    useEffect(() => {
        getPushHistory();
    }, [queryProperties, setTableLoading, setColumnFilterData]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortStatus, columnSortOrder, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (rowData.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [rowData, tableLoading, gridRef]);

    const columnConfig = useMemo(
        () => [
            ...pushHistoryColumnsDef,
            {
                field: 'pushHistoryActions',
                headerName: 'Actions',
                pinned: 'right',
                suppressMovable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (params: ICellRendererParams) => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <StyledLink
                            sx={{
                                pointerEvents:
                                    params.data.status === 'IN_PROGRESS'
                                        ? 'none'
                                        : 'auto',
                                opacity:
                                    params.data.status === 'IN_PROGRESS'
                                        ? '0.5'
                                        : '1'
                            }}
                            to={`${TEMPLATE_ROUTE}/${PUSH_HISTORY}/${params.data?.id}`}
                        >
                            <span>{DETAILED_VIEW}</span>
                        </StyledLink>
                    </div>
                ),
                sortable: false
            } as ColDef
        ],
        []
    );

    return (
        <>
            <Header title={PUSH_HISTORY_TEXT} />
            <div className="control table">
                <TableHeader
                    childrenLeft={
                        <Tooltip
                            arrow
                            title={
                                <div className="tooltip-arrow-text">
                                    {REFRESH}
                                </div>
                            }
                        >
                            <span>
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    disabled={tableLoading}
                                    onClick={getPushHistory}
                                >
                                    <Refresh />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    title={`${totalRows} ${PUSH}`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    rowData={rowData}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </>
    );
};

export const PushHistory = IsLoadingHOC(PushHistoryComponent);
