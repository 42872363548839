// Common
export const ARMIS = 'Armis';
export const PASSWORD_REGEX =
    '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8,30}$';
export const PHONE_REGEX = '^\\d{0,15}$';
export const TENANTS = 'Tenants';
export const EMAIL_REGEX = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
export const SETTINGS = 'Settings';
export const NOTIFICATIONS_LOGGING_HEADER = 'Notifications & Logging';
export const TIMEOUT_ERROR = 'The server is busy. Please try again later.';
export const AMM = 'AMM';
export const USER = 'User';
export const PRIVACY_POLICY_LINK = 'https://www.armis.com/privacy-policy';
export const ARMIS_LINK = 'https://www.armis.com';
export const METADATA_URL_ERROR = 'Enter a valid metadata URL.';
export const METADATA_ERROR = 'Add a valid file or URL.';
export const PARTNER_NAME = 'Partner';
export const SESSION_EXPIRED = 'Session expired';
export const PLACEHOLDER_PASSWORD = '***************';
export const SUBMIT = 'Submit';

// SideNavBar Constants
export const DASHBOARDS = 'Dashboards';
export const ANALYTICS_ITEM_NAME = 'Analytics';
export const PUSH_HISTORY_ITEM_NAME = 'Push History';

// Sign Out
export const LOGOUT_REQUEST = 'Log Out';

// Login
export const LOGIN_TITLE = 'Login';
export const LOGIN_WITH_SSO = 'Login with SSO';

// Basic Auth
export const BA_FORM_TITLE = 'Log in to your Armis account';
export const USERNAME_PLACEHOLDER = 'Username or email';
export const PASSWORD_PLACEHOLDER = 'Password';

export const LOG_IN = 'Log in';
export const NEXT = 'Next';
export const FORGOT_PASSWORD = 'Forgot Password';
export const READ_OUR = 'Read our ';
export const PRIVACY_POLICY = 'Privacy policy';
export const EMAIL_SEND_CONFIRM = 'Email sent to the registered address';
export const USERNAME_REQUIRED = 'Enter a username.';
export const WRONG_CRENDENTIALS = 'Incorrect username or password.';
export const REQUIRED = 'Required field is missing.';
export const USER_AGREEMENT = 'Please check eula agreement first';

// Set Password
export const PW_FORM_TITLE = 'Please provide a new password';
export const NEW_PASSWORD_PLACEHOLDER = 'New Password';
export const NEW_PASSWORD_AGAIN_PLACEHOLDER = 'New Password (again)';
export const PASSWORD_INPUT_BOX = 'password-input-box';
export const PASSWORD_AGAIN_INPUT_BOX = 'password-again-input-box';
export const RESET_PASSWORD = 'Reset Password';
export const DO_NOT_MATCH = 'Passwords do not match.';

// Home
export const HOME_TITLE = 'Armis';

// User Profile
export const MY_PROFILE = 'My Profile';
export const FIRST_NAME = 'First Name';
export const LAST_NAME = 'Last Name';
export const USERNAME = 'Username';
export const EMAIL = 'Email';
export const PHONE = 'Phone';
export const CONFIRM_ACTION = 'Confirm action';
export const CHANGE_PASSWORD = 'Change Password';
export const CHANGE = 'Change';
export const OK = 'OK';
export const CANCEL = 'Cancel';
export const DONE = 'Done';
export const TYPE_PASSWORD = 'Type password';
export const RETYPE_PASSWORD = 'Retype password';
export const NEVER_CHANGED = 'Never changed';
export const LAST_CHANGED = 'Last changed';
export const DUMMY_PASSWORD = '12345678';
export const CONFIRM_ACTION_MODAL_TEXT_1 =
    "The action you're about to perform requires a password.";
export const CONFIRM_ACTION_MODAL_TEXT_2 =
    'Please enter your current password:';
// eslint-disable-next-line max-len
export const CHANGE_PASSWORD_MODAL_TEXT_1 = `You're about to set a new password. The password must be between 8 and 30 characters and include at least one lowercase character, one uppercase character, one number, and one special character.`;
export const PHONE_VALIDATE =
    'Maxim of 20 characters are allowed for a phone number.';
export const FIRST_NAME_VALIDATE =
    'First name should not be empty and contain up to 100 characters.';
export const LAST_NAME_VALIDATE =
    'Last name should not be empty and contain up to 100 characters.';
export const PASSWORD_VALIDATE =
    'The password must be between 8 and 30 characters and include at least one lowercase character, one uppercase character, one number, and one special character.';

// Role Management
export const ROLE_MANAGEMENT = 'Roles & Permissions';
export const PREDEFINED_ROLES = 'Predefined Roles';
export const CUSTOM_ROLES = 'Custom Roles';
export const SHOW_ALL = 'Show all';
export const SHOW_LESS = 'Show less';
export const USERS = 'user(s)';
export const ASSIGNED = 'assigned';
export const SAVE = 'Save';
export const DELETE = 'Delete';
export const DISCARD = 'Discard';
export const CLOSE = 'Close';
export const NO_USER = 'No users assigned';
export const SEARCH_ROLE_PLACEHOLDER = 'Search roles';
export const DELETE_ROLES = 'Delete Roles';
export const DELETE_ROLE = 'Delete Role';
export const SELECTED = 'selected';
export const ADD_ROLE = 'Add role';
export const DEFAULT_ROLE_NAME = 'Role Name';
export const ROLE_UPDATE_SUCCESS = 'Role "%s" has been edited successfully.';
export const ROLE_CREATE_SUCCESS = 'Role "%s" has been added successfully.';
export const DUPLICATE_ROLE_SUCCESS =
    'Role "%s" has been duplicated successfully and is called "%s".';
export const DELETE_ROLE_SUCCESS = 'Role "%s" has been deleted successfully.';
export const DELETE_ROLES_SUCCESS = '%s roles are deleted successfully.';
export const DELETE_ROLE_MESSAGE =
    'You are about to delete role "%s". Are you sure?';
export const DELETE_ROLES_MESSAGE =
    'You are about to delete %s user roles. Are you sure?';
export const DELETE_ROLE_NOT_ALLOWED =
    'Cannot delete a role with assigned users. Reassign the users to another role in the User Management tab and retry.';
export const DELETE_ROLES_NOT_ALLOWED =
    'Cannot complete the action because one or more roles are assigned to users. Assign different roles to affected users in the User Management tab and retry.';
export const USER_MANAGEMENT_TAB = 'User Management tab';
export const ROLE_NAME_ERROR_EMPTY = 'This field is required.';
export const ROLE_NAME_ERROR_MAXLENGTH =
    'Max 100 characters are allowed in role name.';
export const ROLE_NAME_PLACEHOLDER = 'Enter a role name';

// Tenant Management
export const TENANT_NAME = 'Tenant name';
export const SECRET_KEY = 'Secret key';
export const TENANT_URL = 'URL';
export const EDIT_TENANT = 'Edit Tenant';
export const TENANT_PAGE_TITLE = 'Tenants';
export const SEARCH_TENANT_PLACEHOLDER = 'Search tenants';
export const SEARCH_POLICY_PLACEHOLDER = 'Search policies';
export const ACTIVE = 'Active';
export const POLICIES = 'Policies';
export const POLICY = 'Policy';
export const TENANT_POLICY = 'Tenant Policy';
export const OVERRIDE = 'Override';
export const UPDATE = 'Update';
export const POLICY_TEMPLATE_DIFF_OVERRIDE_WARNING =
    'The action you are about to perform cannot be undone. Are you sure you want to continue?';
export const REPORTS = 'Reports';
export const INACTIVE = 'Inactive';
export const MODIFIED = 'Modified';
export const MODIFIED_TOOLTIP =
    "Click 'Compare with template' from the action menu.";
export const SYNC_TENANTS = 'Sync Tenants';
export const SYNC_NOW = 'Sync Now';
export const TENANTS_SYNCED = 'All tenants synced successfully.';
export const TENANTS_SYNCED_INPROGRESS = 'Tenants sync is already in progress.';
export const TENANT_SYNCED = 'Tenant "%s" synced successfully.';
export const TENANT_EDIT_SUCCESS = 'Tenant "%s" has been edited successfully.';
export const TAGS = 'Tags';
export const TEMPLATE_NAME = 'Template Name';
export const INVALID_URL = 'Please enter a valid url';

// Policy View
export const PROMOTE_TO_TEMPLATE = 'Promote';
export const COMPARE_TEMPLATE = 'Compare with template';
export const VIEW_JSON = 'View JSON';
export const PROMOTE = 'Promote';
export const SAVE_CHANGES = 'Save Changes';
export const TEMPLATE = 'Template';
export const TEMPLATES = 'Templates';
export const WITH = 'with';
export const APPLY = 'Apply';
export const RESET = 'Reset';
export const RESOURCE = 'Resource';
export const RESOURCE_SYNCED_SUCCESS = '%s synced successfully.';
export const TEMPLATE_CREATED = 'Template has been created successfully.';
export const ADD_TAGS_PLACEHOLDER = 'Search tags...';
export const CREATE_TAG_INFO = `Note: Press Enter to create a new tag.`;

// Aggregated View
export const AGGREGATED_TENANTS_SEARCH = 'Search tenants';
export const AGGREGATED_VIEW = 'Overview';
export const SYNC_TENANT_WARNING =
    'You are about to sync "%s" tenant data. Are you sure?';
export const SYNC_TENANTS_WARNING =
    'You are about to sync data for all tenants. Are you sure?';
export const SYNC = 'Sync';
export const TABLE_VIEW = 'Table View';
export const TILE_VIEW = 'Tile View';
export const MAXIMIZE_SCREEN = 'Maximize Screen';
export const SYNCED = 'Synced';
export const SYNC_MODAL_TITLE = 'Sync Tenants';
export const SYNC_TENANT_MODAL_TITLE = 'Sync Tenant';
export const REFRESH = 'Refresh';
export const TENANT = 'Tenant';
export const LAST_SYNC = 'Last Sync';
export const COLON = ':';
export const MINUTES = 'minutes';
export const HOURS_CAPS = 'Hours';
export const MINUTES_CAPS = 'Minutes';
export const SECONDS_CAPS = 'Seconds';
export const SORT = 'Sort:';
export const CRITICALITY_LABEL = 'Criticality';
export const INTEGRATIONS_LABEL = 'Integrations';
export const COLLECTORS_LABEL = 'Collectors';
export const OFFLINE_COLLECTORS_LABEL = 'Offline Collectors';
export const OFFLINE_COLLECTORS_API_LABEL = 'offlineCollector';
export const ERROR_INTEGRATIONS_LABEL = 'Error Integrations';
export const ERROR_INTEGRATIONS_API_LABEL = 'errorIntegration';
export const ALERTS_LABEL = 'Alerts';
export const ALERTS_API_LABEL = 'alert';
export const TENANT_NAME_API_LABEL = 'tenantName';
export const A_TO_Z = 'A-Z';
export const DESC = 'desc';
export const ASC = 'asc';
export const DEVICES = 'Devices';
export const SITES = 'Sites';
export const ALERTS = 'Alerts';
export const OFFLINE = 'Offline';
export const ERROR = 'Error';
export const PINNED = 'PINNED';
export const OTHERS = 'OTHERS';
export const TILE = 'Tile';
export const EXPAND_TILE_VIEW = 'tileview-fullscreen';
export const INACTIVE_INTEGRATION = 'inactiveIntegration';
export const DRAFT_INTEGRATION = 'draftIntegration';
export const INITIALIZING_COLLECTOR = 'initializingCollector';
export const PENDING_ACTIVATION_COLLECTOR = 'pendingActivationCollector';
export const SYNCED_SUCCESS = 'Tenants have been synced successfully.';
export const PIE = 'pie';

export const AGGREGATED_VIEW_ALERTS_TOOLTIP_INFO = [
    'Alerts greater than or equal to %s.',
    'Alerts less than %s.'
];
export const AGGREGATED_VIEW_COLLECTORS_TOOLTIP_INFO = [
    'Offline Collectors greater than or equal to %s.',
    'Offline Collectors less than %s.'
];
export const AGGREGATED_VIEW_INTEGRATIONS_TOOLTIP_INFO = [
    'Error Integrations greater than or equal to %s.',
    'Error Integrations less than %s.'
];

// Audit Log
export const AUDIT_LOG_HEADER = 'Audit Log';
export const AUDIT_LOG_TITLE = 'Audit Logs';
export const EXPORT = 'Export';
export const EXPORT_MODAL_TITLE = 'Export Report';
export const EXPORT_MODAL_CONTENT = 'This report will include';
export const EXPORT_MODAL_CONTENT_SPAN = 'audit logs.';
export const EXPORT_MODAL_CONTENT_CSV_SPAN = 'the top 2,500 audit logs out of ';
export const EXPORT_MODAL_CSV_CONTENT = 'To include more rows,';
export const EXPORT_MODAL_CSV_SPAN = ' use CSV format.';
export const EXPORT_OUTPUT_FORMAT_LABEL = 'Output Format';
export const EXPORT_COLUMN_TO_INCLUDE_LABEL = 'Columns to include';
export const EXPORT_EXCEL = 'Excel (.xlsx)';
export const EXPORT_CSV = 'Comma Separated Values (.csv)';
export const EXPORT_XLSX_MAX_VALUE = 2500;
export const TRIGGER = 'Trigger';

// Custom properties
export const CUSTOM_PROPERTIES_TITLE = 'Custom Settings';
export const AGGREGATED_VIEW_SECTION_TITLE = 'Overview Thresholds';
export const AGGREGATED_VIEW_SECTION_PARAGRAPH =
    'These are the details that you should fill out to filter the alerts and to maintain the threshold value for each tenant.';
export const ALERT_THRESHOLD_VALUE = 'Alert Threshold';
export const ALERT_ASQ = 'Alert ASQ';
export const DEVICES_ASQ = 'Devices ASQ';
export const INTEGRATION_ERROR_THRESHOLD = 'Integration Error Threshold';
export const OFFLINE_COLLECTORS = 'Offline Collectors Threshold';
export const AGGREGATED_SCHEDULER = 'Sync Interval';
export const SET_TO_DEFAULT = 'Set to Default';
export const CRON_RANGE_INVALID_SECONDS = 'Value should be between 2 to 59.';
export const CRON_RANGE_INVALID_MINUTES = 'Value should be between 1 to 59.';
export const CRON_RANGE_INVALID_HOURS = 'Value should be between 1 to 23.';
export const MIN_VALUE_INVALID = 'Min value should be %s.';
export const MAX_VALUE_INVALID = 'Max value should be %s.';
export const INVALID_VALUE_INSERT = 'This value is not supported.';
export const PROPERTIES_UPDATED_SUCCESS =
    'Properties have been updated successfully.';
export const TO_DEFAULT_PROPERTIES_WARNING =
    'You are about to set default properties value. Are you sure?';

// User Management
export const USER_MANAGEMENT = 'User Management';
export const USER_MANAGEMENT_HEADER = 'User';
export const USERS_MANAGEMENT_HEADER = 'Users';
export const ADD_USERS = 'Add User';
export const SEARCH_USER_PLACEHOLDER = 'Search users';
export const EDIT_USER = 'Edit User';
export const DELETE_USERS = 'Delete Users';
export const DELETE_USER = 'Delete User';
export const DELETE_SINGLE_USER =
    'You are about to delete user "%s". Are you sure?';
export const DELETE_MULTIPLE_USER =
    'You are about to delete %s users. Are you sure?';
export const MAX_LENGTH_INVALID = 'Max length should be %s';
export const USER_ADDED_SUCCESS = 'User "%s" has been added successfully.';
export const USER_UPDATED_SUCCESS = 'User "%s" has been edited successfully.';
export const USER_DELETED_SUCCESS = 'User "%s" has been deleted successfully.';
export const USERS_DELETED_SUCCESS = 'Users deleted successfully.';
export const FIRSTNAME = 'firstname';
export const LASTNAME = 'lastname';
export const USER_NAME = 'username';
export const PHONE_NUMBER = 'phonenumber';
export const ROLES = 'roles';
export const ADD_USER = 'Add User';
export const ADD_USER_BUTTON = 'Add';
export const PHONENUMBER = 'Phone Number';
export const OPTIONAL = '(optional)';
export const SELECT_ROLES = 'Select Roles';
export const ALL = 'All';
export const TOTAL_SELECTED = '%s Selected';
export const ADMIN = 'Admin';

// Policy Templates
export const POLICY_TEMPLATES = 'Policy Templates';
export const DELETE_POLICIES = 'Delete Policies';
export const PUSH_POLICY = 'Push Policies';
export const EDIT = 'Edit';
export const COMPARE_POLICY = 'Compare with tenant policy';
export const MODIFIED_TEMPLATE_TOOLTIP =
    "Click 'Compare with tenant policy' in the action column";
export const STATUS_TEXT = 'Status';
export const PUSH_TEMPLATE_COLUMN_INFO = [
    {
        total: 0,
        success: 0,
        info: 'Click the Status to get detailed information.'
    },
    {
        total: 2,
        success: 0,
        info: 'This template has not been pushed successfully to any tenants.'
    },
    {
        total: 2,
        success: 1,
        info: 'This template has been pushed successfully to 1 out of 2 tenants.'
    },
    {
        total: 2,
        success: 2,
        info: 'This template has been pushed successfully to all 2 tenants.'
    }
];

export const PUSH_TEMPLATE_TOOLTIP_INFO = [
    {
        total: 2,
        success: 0,
        info: 'None of the templates has been pushed successfully to the tenant.'
    },
    {
        total: 2,
        success: 1,
        info: '1 out of 2 templates has been pushed successfully to the tenant.'
    },
    {
        total: 2,
        success: 2,
        info: '2 out of 2 templates has been pushed successfully to the tenant.'
    }
];

export const PUSH_TEMPLATE_STATUS = [
    {
        status: 'SUCCESS',
        info: 'This template has been pushed successfully to the tenant.'
    },
    {
        status: 'FAILED',
        info: 'The template has not been pushed successfully to the tenant. Hover over info to see the reason.'
    }
];
export const DELETE_POLICY = 'Delete Policy';
export const DELETE_POLICY_WARNING =
    'You are about to delete "%s". Are you sure?';
export const DELETE_POLICIES_WARNING =
    'You are about to delete %s policies. Are you sure?';
export const DELETE_POLICIES_FROM_TENANT =
    'Delete selected policies from the linked tenants.';
export const DELETE_POLICY_FROM_TENANT =
    'Delete selected policy from the linked tenants.';
export const POLICY_DELETED_SUCCESS = 'Policy deleted successfully.';
export const POLICIES_DELETED_SUCCESS = 'Policies deleted successfully.';
export const CONDITIONS = 'Conditions';
export const AND = 'And';
export const OR = 'Or';
export const ADD = 'Add';
export const TYPE = 'Type';
export const SEVERITY = 'Severity';
export const ALERT = 'Alert';
export const POLICY_TEMPLATE_CONDITIONS_QUERY = 'Enter %s conditions query.';
export const POLICY_SEVERITY_HEADER = 'The policy will generate a';
export const POLICY_TEMPLATE_UPDATE_SUCCESS =
    'Policy template edited successfully.';
export const ADVANCED_JSON_EDIT = 'Advanced Json Edit';
export const POLICY_TEMPLATE_JSON_EDIT_INFO =
    'Note: An invalid parameter or logic will invalidate the JSON file.';
export const CONTINUE = 'Continue';
export const EDIT_RESET_WARNING_MSG =
    'You have unsaved changes. Are you sure you want to continue?';
export const CONFIRM_DISCARD_CHANGES = 'Confirm Discard Changes';
export const INVALID_POLICY_FORMAT =
    "Invalid policy structure found. Use the 'Advanced JSON Edit' option to edit.";
export const PUSH_WIZARD_STEP_1 = '1. Select tenants';
export const PUSH_WIZARD_STEP_2 = '2. Validate & Push';
export const PREVIOUS = 'Previous';
export const BACK = 'Back';
export const PUSH = 'Push';
export const NO_CONFLICT = 'No Conflict';
export const NO_CONFLICT_DESC =
    'The template is not present in the tenant. Select the row to push the template to the tenant.';
export const CONFLICT = 'Conflict';
export const CONFLICT_DESC =
    // eslint-disable-next-line max-len
    'Either the template or linked resource is updated or there is a resource in the tenant with the same name as the template. Select the row to override the linked resource or link the tenant resource with the template.';
export const ALREADY_PUSHED = 'Already Pushed';
export const ALREADY_PUSHED_DESC =
    'The template is already pushed to the selected tenant.';
export const ERROR_DESC =
    'This template can’t be pushed to the tenant because a resource with the same name is already linked with another template.';
export const CONFIRM_PUSH = 'Confirm Push';
export const CONFIRM_PUSH_WARNING =
    'By default, templates with any conflicts are not selected. Are you sure you want to push all the selected templates?';
export const PUSH_TEMPLATE_FEEDBACK_MSG =
    'Your request to push templates has started.';
export const SEARCH_TENANTS_NAME = 'Search tenants';
export const ENABLE_POLICIES_IN_TENANT =
    'Enable policies in the tenant after push (policies are disabled by default).';
export const CONFLICT_ON_TENANT_PUSH_MSG = 'Conflicts found on this tenant.';
export const TEMPLATE_PLACEHOLDER = 'Enter a template name';

// Hardcoded Policy Conditions type
export const IP_CONNECTION = 'IP Connection';
export const ACTIVITY = 'Activity';
export const DEVICE = 'Device';
export const VULNERABILITY = 'Vulnerability';
export const POLICY_DRIVEN_ACTIONS = 'Policy-driven Actions';

// Hardcoded Policy Action type severity
export const LOW = 'Low';
export const MEDIUM = 'Medium';
export const HIGH = 'High';

// SSO & SAML
export const SSO_AND_SAML = 'SSO & SAML';
export const CHOOSE_METADATA_FORMAT = 'Choose a Metadata Format:';
// SP Details
export const SAML_SP_DETAILS = 'SAML Service Provider (SP) Details';
export const SAML_SP_DESCRIPTION =
    'These are the details that you need to fill out in your SAML configuration page of your IDP.';
export const SAML_SP_SSO_URL = 'SP Single Sign On URL';
export const SAML_SP_ENTITY_ID = 'SP Entity ID';
// IDP Details
export const SAML_IDP_DETAILS = 'SAML Identity Provider (IDP) Details';
export const SAML_IDP_DESCRIPTION =
    'These are the details that you need to fill out. They should be available from your IDP.';
export const SAML_IDP_SSO_URL = 'SP Single Sign On URL';
export const SAML_IDP_ISSUER = 'IDP Issuer';
export const SAML_IDP_CERTIFICATE = 'IDP X.509 Certificate';
export const COPY = 'Copy';
export const UPLOAD_FILE = 'Upload File';
export const METADATA_FILE = 'Metadata File';
export const METADATA_URL = 'Metadata URL';
export const QUERY_SELECTOR_CERTIFICATE = 'X509Certificate';
export const QUERY_SELECTOR_ENTITYDESCRIPTOR = 'EntityDescriptor';
export const QUERY_SELECTOR_SINGLESIGNONSERVICE = 'SingleSignOnService';
export const ATTRIBUTE_BINDING = 'Binding';
export const ATTRIBUTE_LOCATION = 'Location';
export const ATTRIBUTE_ENTITY = 'entityID';
export const HTTP_POST_LABEL = 'HTTP-POST';

// Dashboard
export const DASHBOARD = 'Dashboards';
export const ASQ = 'ASQ: ';
export const MENUITEM_CLICK = 'menuItemClick';

// TopBar
export const ALL_TENANTS = 'All Tenants';
export const SELECT_TENANTS = 'Select Tenants';
export const TOTAL_SELECTED_TENANTS = '%s Tenants';

// Push Policy
export const PUSH_HISTORY_TEXT = 'Push History';
export const DETAILED_VIEW = 'Detailed View';
export const TEMPLATE_PUSH_STATUS_TOOLTIP =
    '%s out of %t templates have been pushed successfully to the tenant.';
export const PUSH_SUMMARY = 'Push Summary';
export const SEARCH_TEMPLATE_PLACEHOLDER = 'Search templates';
export const IN_PROGRESS = 'In Progress';
export const SUCCESS = 'Success';
export const FAILED = 'Failed';
export const PUSH_ID = 'Push ID:';
export const TIME = 'Time:';
export const PUSHED_BY = 'Pushed By:';
export const STATUS = 'Status:';

// DashBoard
export const DEFAULT_QUERY = 'in:devices name:(%s)';
export const SEARCH = 'Search';
