import React from 'react';

import { Dialog, Button } from '@armis/armis-ui-library';
import { DialogContent, DialogActions } from '@mui/material';
import { CANCEL } from 'src/constants/LabelText';

import { ALLOW_BUTTON, ModalProps } from './Modal.types';

export const Modal = ({
    isModalOpen,
    isDisabledSubmitBtn,
    title,
    children,
    submitBtnLabel,
    onCancel,
    onSubmit,
    className,
    displayBtn = 'all',
    dialogActionsButton
}: ModalProps) => (
    <Dialog
        onClose={onCancel}
        open={isModalOpen}
        PaperProps={{
            className: `simple-popup ${className}`
        }}
        sx={{ zIndex: 11000 }}
    >
        <header className="header">
            {/* <span className="spacer left" /> */}
            <span className="title"> {title} </span>
            <span className="spacer right" />
        </header>
        <form onSubmit={e => e.preventDefault()}>
            <DialogContent
                dividers
                sx={{ overflowY: 'auto', maxHeight: '75vh' }}
            >
                {children}
            </DialogContent>
            <DialogActions>
                {dialogActionsButton}
                {(displayBtn === ALLOW_BUTTON.ALL ||
                    displayBtn === ALLOW_BUTTON.CANCEL) && (
                    <Button
                        color="primary"
                        onClick={onCancel}
                        size="large"
                        style={{ fontWeight: 'bolder' }}
                        sx={{
                            fontSize: '20px',
                            lineHeight: '1'
                        }}
                        variant="outlined"
                    >
                        {CANCEL}
                    </Button>
                )}
                {(displayBtn === ALLOW_BUTTON.ALL ||
                    displayBtn === ALLOW_BUTTON.SUBMIT) && (
                    <Button
                        disabled={isDisabledSubmitBtn}
                        onClick={onSubmit}
                        size="large"
                        style={{ fontWeight: 'bolder' }}
                        sx={{
                            fontSize: '20px',
                            lineHeight: '1'
                        }}
                        type="submit"
                        variant="contained"
                    >
                        {submitBtnLabel}
                    </Button>
                )}
            </DialogActions>
        </form>
    </Dialog>
);
