import React, { useEffect, useState } from 'react';

import {
    AsqDropDown,
    Tooltip,
    MultiSelect,
    CHECKBOX_STATUS,
    MenuItemProps,
    Select,
    Switch
} from '@armis/armis-ui-library';
import { MenuItem } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Resources, Privileges } from 'src/constants/CommonConstants';
import {
    ALL_TENANTS,
    AMM,
    LOGOUT_REQUEST,
    MENUITEM_CLICK,
    MY_PROFILE,
    PARTNER_NAME,
    SELECT_TENANTS,
    TOTAL_SELECTED_TENANTS,
    USER
} from 'src/constants/LabelText';
import {
    ANALYTICS,
    DASHBOARDS_ROUTE,
    LOGIN,
    MY_PROFILE as MY_PROFILE_ROUTE
} from 'src/constants/RouteConstants';
import {
    displayErrorMessage,
    isActionHasPermissions
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Tenant } from 'src/pages/containers/TenantManagement/TenantManagement.types';
import {
    getRecommendedAsq,
    getTenants,
    logout
} from 'src/services/api.service';
import {
    selectDashBoardQuery,
    selectIsDataFetchInProgress,
    setDashBoardQuery,
    setDashBoardTenants
} from 'src/store/slices/dashBoardSlice';
import {
    selectThemeMode,
    setTheme as setThemeInStore
} from 'src/store/slices/themeSlice';
import { selectUser } from 'src/store/slices/userSlice';
import { ErrorResponse, GenericResponseData } from 'src/types/APIResponseTypes';
import { FilterItems } from 'src/types/CommonTypes';

import {
    StyledTopBar,
    ProfileContainer,
    StyledHeader,
    UserNameContainer,
    UserEmailContainer,
    StyledAvatar,
    StyledSeperator,
    loaderContainerStyle,
    StyledFooter,
    DarkModeMenuItem
} from './TopBar.style';
import { TopBarProps } from './TopBar.types';

const TopBarComp = ({ setIsLoading }: TopBarProps) => {
    const [search, setSearch] = useState('');
    const [dashBoardQueryOnBlur, setDashBoardQueryOnBlur] = useState('');
    const [recommendQueries, setRecommendQueries] = useState([]);
    const [tenantsCount, setTenantsCount] = useState(ALL_TENANTS);
    const [tenants, setTenants] = useState<FilterItems[]>([]);
    const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dashBoardQuery = useSelector(selectDashBoardQuery);
    const isDataFetchInProgress = useSelector(selectIsDataFetchInProgress);
    const selectedTheme = useSelector(selectThemeMode);
    const logoutClickHandler = () => {
        logout()
            .then(() => {
                navigate(LOGIN, { replace: true });
                dispatch({ type: LOGOUT_REQUEST });
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
            });
    };

    const resetState = () => {
        setSearch('');
        dispatch(setDashBoardQuery(''));
        setDashBoardQueryOnBlur('');
        setSelectedTenants(tenants.map(element => element.id));
    };

    useEffect(() => {
        const resetStateBool = location.state?.resetTopBarState ?? true;
        if (resetStateBool) {
            resetState();
        }
    }, [location.state, location.pathname]);

    useEffect(() => {
        const makeAnApiCall = async () => {
            try {
                const res: AxiosResponse<GenericResponseData<Tenant>> =
                    await getTenants('', false);
                const getTotalTenants = res.data.content.map((item: any) => ({
                    label: item.name,
                    id: item.id,
                    checkStatus: CHECKBOX_STATUS.CHECKED,
                    labelRender: <span>{item.name}</span>
                }));
                setTenants(getTotalTenants);
                setSelectedTenants(getTotalTenants.map(element => element.id));

                const res1 = await getRecommendedAsq();
                setRecommendQueries(res1.data);
            } catch (err: any) {
                displayErrorMessage(err);
            } finally {
                setIsLoading(false);
            }
        };
        if (isActionHasPermissions(user, Resources.policy, [Privileges.read])) {
            setIsLoading(true);
            makeAnApiCall();
        }
    }, []);

    const querySelected = (query: any, action?: string) => {
        const dashboardQuery = query.label === '' ? '' : query.delayMessage;
        setDashBoardQueryOnBlur(dashboardQuery);
        if (action !== MENUITEM_CLICK) {
            dispatch(setDashBoardQuery(dashboardQuery));
            dispatch(setDashBoardTenants(selectedTenants));
            if (
                location.pathname !== `${ANALYTICS}/${DASHBOARDS_ROUTE}` &&
                query.label !== ''
            ) {
                navigate(`${ANALYTICS}/${DASHBOARDS_ROUTE}`, {
                    state: { resetTopBarState: false }
                });
            }
        }
    };

    const tooltipContent = (
        <div
            style={{
                maxWidth: '20vw'
            }}
        >
            <StyledHeader>
                <UserNameContainer>
                    {`${user?.firstname || AMM} ${user?.lastname || USER}`}
                </UserNameContainer>
                <UserEmailContainer>{user?.username}</UserEmailContainer>
            </StyledHeader>
            <MenuItem
                component={Link}
                disabled={user.isAmmUser}
                onClick={() => setIsOpen(false)}
                state={{
                    resetTopBarState: true
                }}
                to={MY_PROFILE_ROUTE}
            >
                {MY_PROFILE}
            </MenuItem>
            <MenuItem onClick={() => setIsOpen(false)}>
                <DarkModeMenuItem>
                    Dark Mode
                    <Switch
                        checked={selectedTheme === 'dark'}
                        onChange={() => {
                            const newTheme =
                                selectedTheme === 'dark' ? 'light' : 'dark';
                            localStorage.setItem('armis-theme', newTheme);
                            dispatch(setThemeInStore(newTheme));
                        }}
                    />{' '}
                </DarkModeMenuItem>
            </MenuItem>
            <MenuItem
                onClick={logoutClickHandler}
                sx={{ borderBottom: 'none' }}
            >
                {LOGOUT_REQUEST}
            </MenuItem>
            {user.partnerName && (
                <Tooltip
                    arrow
                    placement="left"
                    title={
                        <div className="tooltip-arrow-text">
                            {user.partnerName}
                        </div>
                    }
                >
                    <StyledFooter>
                        {PARTNER_NAME}: <span>{user.partnerName}</span>
                    </StyledFooter>
                </Tooltip>
            )}
        </div>
    );
    useEffect(() => {
        const totalRolesSelected = selectedTenants.length;
        if (totalRolesSelected === 0) {
            setTenantsCount(SELECT_TENANTS);
        } else if (totalRolesSelected === tenants.length) {
            setTenantsCount(ALL_TENANTS);
        } else if (totalRolesSelected === 1) {
            const tenantName = tenants.find(
                element => element.id === selectedTenants[0]
            );
            setTenantsCount(tenantName?.label!);
        } else {
            setTenantsCount(
                TOTAL_SELECTED_TENANTS.replace(
                    '%s',
                    totalRolesSelected.toString()
                )
            );
        }
        const updatedTenants = tenants.map(tenant =>
            selectedTenants.find(tenant1 => tenant1 === tenant.id)
                ? { ...tenant, checkStatus: CHECKBOX_STATUS.CHECKED }
                : { ...tenant, checkStatus: CHECKBOX_STATUS.UNCHECKED }
        );
        setTenants(updatedTenants);
    }, [selectedTenants]);

    const onTextBoxBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setDashBoardQueryOnBlur(event.target.value);
    };

    const onChangeInput = (searchInput: string) => {
        setSearch(searchInput);
        if (searchInput === '') {
            dispatch(setDashBoardQuery(''));
            setDashBoardQueryOnBlur('');
        }
    };

    return (
        <StyledTopBar>
            <ProfileContainer>
                <ProtectedAction
                    hasPermissions={[Privileges.read]}
                    resource={Resources.policy}
                >
                    <>
                        <div style={{ flex: '4' }}>
                            <AsqDropDown
                                recentQueries={[]}
                                recommendedQueries={recommendQueries}
                                selectedQuery={querySelected}
                                textboxProps={{
                                    sx: {
                                        width: '100%'
                                    },
                                    onBlur: onTextBoxBlur,
                                    onChangeInput,
                                    value: search,
                                    disabled: isDataFetchInProgress,
                                    ...(isDataFetchInProgress && {
                                        onClick: e => e.preventDefault()
                                    })
                                }}
                            />
                        </div>
                        <Select
                            defaultValue={ALL_TENANTS}
                            disabled={isDataFetchInProgress}
                            MenuProps={{
                                sx: { zIndex: 10002 }
                            }}
                            onClose={() => {
                                if (dashBoardQuery || dashBoardQueryOnBlur) {
                                    if (
                                        dashBoardQuery !==
                                            dashBoardQueryOnBlur &&
                                        dashBoardQueryOnBlur
                                    ) {
                                        dispatch(
                                            setDashBoardQuery(
                                                dashBoardQueryOnBlur
                                            )
                                        );
                                        if (
                                            location.pathname !==
                                            `${ANALYTICS}/${DASHBOARDS_ROUTE}`
                                        ) {
                                            navigate(
                                                `${ANALYTICS}/${DASHBOARDS_ROUTE}`,
                                                {
                                                    state: {
                                                        resetTopBarState: false
                                                    }
                                                }
                                            );
                                        }
                                    }
                                    dispatch(
                                        setDashBoardTenants(selectedTenants)
                                    );
                                }
                            }}
                            renderValue={() => tenantsCount}
                            sx={{ flex: '1' }}
                            value={tenantsCount}
                        >
                            <MultiSelect
                                isAllSelected
                                items={tenants}
                                onSelectionChanged={(
                                    selectedItems: MenuItemProps[]
                                ) => {
                                    setSelectedTenants(
                                        selectedItems.map(element => element.id)
                                    );
                                }}
                                showSelectAllOption
                            />
                        </Select>
                        <StyledSeperator />
                    </>
                </ProtectedAction>
                <Tooltip
                    arrow
                    leaveDelay={50}
                    onClose={() => setIsOpen(false)}
                    onOpen={() => setIsOpen(true)}
                    open={isOpen}
                    PopperProps={{ placement: 'bottom-end' }}
                    title={tooltipContent}
                >
                    <StyledAvatar sx={{ width: 32, height: 32 }}>
                        {user?.firstname?.charAt(0) || 'A'}
                    </StyledAvatar>
                </Tooltip>
            </ProfileContainer>
        </StyledTopBar>
    );
};

export const TopBar = IsLoadingHOC(TopBarComp, loaderContainerStyle);
