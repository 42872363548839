import { EDIT, SYNC } from 'src/constants/LabelText';
import { Policy } from 'src/pages/containers/TenantView/Policies/Policies.types';

export interface TenantManagementProps {
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

export interface Tenant {
    createdby: string;
    id: string;
    lastSynced: number;
    partner: {
        id: string;
        syncInProgress: boolean;
        active: boolean;
    };
    policyJson?: any;
    modified: boolean;
    name: string;
    username: string;
    tenantUrl: string;
    totalPolicies: number;
    totalReports: number;
    pinOrder: number;
    templates?: Policy[];
    valid?: boolean;
}

export const TenantActions = {
    EDIT,
    SYNC
};
