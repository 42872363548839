import {
    AND,
    OR,
    DELETE,
    VIEW_JSON,
    EDIT,
    IP_CONNECTION,
    ACTIVITY,
    DEVICE,
    VULNERABILITY,
    LOW,
    MEDIUM,
    HIGH,
    PUSH_POLICY,
    NO_CONFLICT,
    CONFLICT,
    ERROR,
    ALREADY_PUSHED,
    NO_CONFLICT_DESC,
    CONFLICT_DESC,
    ERROR_DESC,
    ALREADY_PUSHED_DESC
} from 'src/constants/LabelText';

export const AvailableTemplatePolicyActions = {
    DELETE,
    VIEW_JSON,
    EDIT,
    PUSH_POLICY
};

export const allowedRulesOperator = {
    or: OR,
    and: AND
};

export const policyTemplateConditionsType: { [key: string]: string } = {
    IP_CONNECTION,
    ACTIVITY,
    DEVICE,
    VULNERABILITY
};

export const allowedSeverities = {
    low: LOW,
    medium: MEDIUM,
    high: HIGH
};

export const requiredKeysinPolicyJson = [
    'name',
    'ruleType',
    'rules',
    'actions'
];

export const allowedPushWizardTab = {
    SELECT_TENANT: 'selectTenant',
    VALIDATE_PUSH: 'validatePush'
};

export const pushConflictStatus: {
    [key: string]: {
        label: string;
        color: string;
        description: string;
    };
} = {
    NO_CONFLICT: {
        label: NO_CONFLICT,
        color: 'success',
        description: NO_CONFLICT_DESC
    },
    CONFLICT: {
        label: CONFLICT,
        color: 'warning',
        description: CONFLICT_DESC
    },
    ERROR: {
        label: ERROR,
        color: 'error',
        description: ERROR_DESC
    },
    ALREADY_PUSHED: {
        label: ALREADY_PUSHED,
        color: 'info',
        description: ALREADY_PUSHED_DESC
    }
};
