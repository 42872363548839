import React, { useState, useEffect, useRef, useMemo } from 'react';

import {
    Button,
    CustomHeader,
    IconButton,
    MoreAction,
    MultiSelect,
    Pagination,
    StyledMenuPaper,
    Sync,
    Table,
    TableNoData,
    TableSkeleton
} from '@armis/armis-ui-library';
import { PaperProps, Menu } from '@mui/material';
import {
    ColDef,
    ICellRendererParams,
    SelectionChangedEvent
} from 'ag-grid-community';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    PARTNER_TENANTS_API_MAPPING,
    SYNC_STATUS
} from 'src/constants/APIResponse';
import { Resources } from 'src/constants/CommonConstants';
import {
    SEARCH_TENANT_PLACEHOLDER,
    SYNC,
    SYNC_MODAL_TITLE,
    SYNC_TENANTS,
    SYNC_TENANTS_WARNING,
    SYNC_TENANT_MODAL_TITLE,
    SYNC_TENANT_WARNING,
    TENANT,
    TENANTS,
    TENANTS_SYNCED,
    TENANT_PAGE_TITLE,
    TENANT_SYNCED,
    TENANTS_SYNCED_INPROGRESS,
    TENANT_NAME,
    EDIT_TENANT,
    TENANT_EDIT_SUCCESS,
    USERNAME,
    TENANT_URL,
    SUBMIT
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    tenantAPIMapping
} from 'src/constants/TableConstants';
import { tenantColumnsConfig, urlFilter } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    showToast,
    TOAST_TYPE,
    validateFormFields
} from 'src/helpers/utility';
import { Validators } from 'src/helpers/Validators';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { StyledOption } from 'src/pages/containers/TenantView/Policies/Policies.style';
import {
    editPartnerTenant,
    getTenants,
    getTenantsSyncStatus,
    syncTenants
} from 'src/services/api.service';
import { axiosController } from 'src/services/axiosInstance';
import { selectUser } from 'src/store/slices/userSlice';
import {
    GenericResponseData,
    SyncTenantGetResponse,
    SyncTenantPostResponse
} from 'src/types/APIResponseTypes';
import {
    ChildRefProp,
    FilterItems,
    Map,
    ModalFieldType
} from 'src/types/CommonTypes';

import {
    actionFormMetaData,
    initialData,
    tenantActionOptions
} from './constants';
import { EditTenant } from './EditTenant';
import {
    Tenant,
    TenantActions,
    TenantManagementProps
} from './TenantManagement.types';
import { StyledMenuItem } from '../AggregatedView/AggregatedView.style';

const tenantSortOrder: Map<number> = {};
const tenantSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];

createRelatedObject(
    tenantAPIMapping,
    tenantSortOrder,
    tenantSortStatus,
    columnsFilterItems
);

const TenantManagementComponent = ({ setIsLoading }: TenantManagementProps) => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        modelOpen,
        setModelOpen,
        queryProperties,
        setQueryProperties
    } = useTable({
        sortOrderObj: tenantSortOrder,
        sortStatusObj: tenantSortStatus,
        columnsFilterItems
    });
    const [tenantList, setTenantList] = useState<Tenant[]>([]);
    const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
    const [totalRows, setTotalRows] = useState<null | number>(null);
    const [isSyncing, setIsSyncing] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);
    const [anchorElForActions, setAnchorElForActions] =
        useState<null | HTMLElement>(null);
    const [selectedTenantAction, setSelectedTenantAction] = useState('');
    const [modalFields, setModalFields] = useState<ModalFieldType>(initialData);

    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);
    const selectedTenant = useRef<Tenant | null>(null);
    const pollIntervalId = useRef<any>(0);

    const currentUser = useSelector(selectUser);

    const isArmisUser = currentUser.username.includes('@armis.com');

    const resetStateAfterSync = (resetLoading: boolean = false) => {
        setTableLoading(resetLoading);
        setIsSyncing(false);
        setSelectedTenants([]);
        clearTimeout(pollIntervalId.current);
        pollIntervalId.current = 0;

        gridRef.current?.api.deselectAll();
    };

    const pollTenantsSyncStatus = (tenantsId: string[]) => {
        getTenantsSyncStatus()
            .then((res: AxiosResponse<SyncTenantGetResponse>) => {
                const { status, message } = res.data;
                if (status === SYNC_STATUS.SUCCESS) {
                    const successMsg =
                        tenantsId.length === 1
                            ? TENANT_SYNCED.replace(
                                  '%s',
                                  tenantList.find(t => t.id === tenantsId[0])
                                      ?.name!
                              )
                            : TENANTS_SYNCED;
                    showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
                    setRefreshPage(prevValue => !prevValue);
                    resetStateAfterSync(true);
                } else if (status === SYNC_STATUS.FAILED) {
                    showToast(message, TOAST_TYPE.ERROR, TOAST_ID);
                    setRefreshPage(prevValue => !prevValue);
                    resetStateAfterSync(true);
                } else if (status === SYNC_STATUS.IN_PROGRESS) {
                    pollIntervalId.current = setTimeout(() => {
                        pollTenantsSyncStatus(tenantsId);
                    }, 1500);
                }
            })
            .catch(err => {
                displayErrorMessage(err);
                resetStateAfterSync();
            });
    };

    const syncMultipleTenants = () => {
        setTableLoading(true);
        setIsSyncing(true);
        const tenantsId = selectedTenant.current
            ? [(selectedTenant.current as Tenant).id]
            : selectedTenants;
        selectedTenant.current = null;
        syncTenants(tenantsId)
            .then((res: AxiosResponse<SyncTenantPostResponse>) => {
                if (!res.data.syncInProgress) {
                    pollTenantsSyncStatus(tenantsId);
                } else {
                    showToast(
                        TENANTS_SYNCED_INPROGRESS,
                        TOAST_TYPE.INFO,
                        TOAST_ID
                    );
                    resetStateAfterSync();
                }
            })
            .catch(err => {
                displayErrorMessage(err);
                resetStateAfterSync();
            });
    };

    const columnConfig = useMemo(
        () => [
            ...tenantColumnsConfig,
            {
                field: 'actions',
                headerName: 'Actions',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                pinned: 'right',
                initialWidth: 85,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (params: ICellRendererParams<Tenant>) => (
                    <IconButton
                        className="Icon-Hover-Effect"
                        data-testid="tenant-actions"
                        onClick={e => {
                            setAnchorElForActions(e.currentTarget);
                            selectedTenant.current = params.data!;
                        }}
                        size="small"
                    >
                        <MoreAction />
                    </IconButton>
                ),
                sortable: false
            } as ColDef
        ],
        [setModelOpen]
    );

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true
        }),
        [columnSortOrder, onSortChangedCall, columnSortStatus]
    );

    const searchTenantContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    disabled={isSyncing}
                    onChange={newValue => {
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }));
                    }}
                    placeholder={SEARCH_TENANT_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy, isSyncing, setQueryProperties]
    );

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    useEffect(() => {
        setTableLoading(true);
        getTenants(convertQueryObjectToParams(queryProperties))
            .then((res: AxiosResponse<GenericResponseData<Tenant>>) => {
                setTenantList(res.data.content);
                setTotalRows(res.data.totalElements);
            })
            .catch(err => {
                displayErrorMessage(err);
                setTenantList([]);
                setTotalRows(0);
            })
            .finally(() => {
                setTableLoading(false);
                setSelectedTenants([]);
                gridRef.current?.api.deselectAll();
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            });
    }, [queryProperties, refreshPage, setTableLoading]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (tenantList.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [tenantList, tableLoading, gridRef]);

    useEffect(
        () => () => {
            axiosController.controller.abort();
            clearTimeout(pollIntervalId.current);
        },
        []
    );

    const rowSelectionChangeHandler = (
        event: SelectionChangedEvent<Tenant>
    ) => {
        setSelectedTenants(
            event.api.getSelectedRows().map(tenant => tenant.id)
        );
    };

    const getModalTitle = () => {
        if (selectedTenantAction === TenantActions.EDIT) {
            return EDIT_TENANT;
        }
        return selectedTenant.current || selectedTenants.length === 1
            ? SYNC_TENANT_MODAL_TITLE
            : SYNC_MODAL_TITLE;
    };

    const resetActionChanges = () => {
        setSelectedTenantAction('');
        setAnchorElForActions(null);
        setModelOpen(false);
        setModalFields(initialData);
        selectedTenant.current = null;
    };

    const submitButtonClick = () => {
        if (
            !validateFormFields(setModalFields, modalFields, actionFormMetaData)
        ) {
            const keysObject = Object.keys(modalFields);
            const dataObject = keysObject.reduce(
                (totalObject: any, currentValue) => {
                    if (
                        currentValue === USERNAME ||
                        Validators.validateNotEmpty(
                            modalFields[currentValue].value as string
                        )
                    ) {
                        let modalFieldValue = modalFields[currentValue].value;
                        if (currentValue === TENANT_URL) {
                            modalFieldValue = urlFilter(
                                modalFieldValue as string
                            );
                        }
                        totalObject[PARTNER_TENANTS_API_MAPPING[currentValue]] =
                            modalFieldValue;
                    }

                    return totalObject;
                },
                {}
            );
            setIsLoading(true);
            editPartnerTenant((selectedTenant.current as Tenant).id, dataObject)
                .then(() => {
                    const successMsg = TENANT_EDIT_SUCCESS.replace(
                        '%s',
                        (selectedTenant.current as Tenant).name
                    );
                    showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
                    resetActionChanges();
                    setRefreshPage(prevValue => !prevValue);
                })
                .catch(err => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <>
            <Header title={TENANT_PAGE_TITLE}>
                <Button
                    className="header-add-button"
                    color="primary"
                    disabled={!selectedTenants.length || tableLoading}
                    onClick={() => {
                        setModelOpen(true);
                        setSelectedTenantAction(TenantActions.SYNC);
                    }}
                    startIcon={<Sync />}
                    style={{ padding: '8px 10px 11px 8px' }}
                    sx={{
                        '& .MuiButton-startIcon': {
                            marginTop: '4px'
                        },
                        '& .header-add-button': {
                            padding: '8px 10px'
                        }
                    }}
                    variant="contained"
                >
                    {SYNC_TENANTS}
                </Button>
            </Header>
            <div className="control table">
                <TableHeader
                    childrenLeft={searchTenantContainer}
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedTenants.length}
                    title={`${totalRows} ${
                        totalRows === 1 ? `${TENANT}` : `${TENANTS}`
                    }`}
                />
                <Modal
                    className={
                        selectedTenantAction === TenantActions.EDIT
                            ? 'wide'
                            : ''
                    }
                    displayBtn="all"
                    isModalOpen={modelOpen}
                    onCancel={() => {
                        resetActionChanges();
                    }}
                    onSubmit={() => {
                        if (selectedTenantAction === TenantActions.SYNC) {
                            syncMultipleTenants();
                            resetActionChanges();
                        } else {
                            submitButtonClick();
                        }
                    }}
                    submitBtnLabel={
                        selectedTenantAction === TenantActions.SYNC
                            ? SYNC
                            : SUBMIT
                    }
                    title={getModalTitle()}
                >
                    {selectedTenantAction === TenantActions.SYNC ? (
                        <WarningModalContainer
                            text={
                                selectedTenant.current ||
                                selectedTenants.length === 1
                                    ? SYNC_TENANT_WARNING.replace(
                                          '%s',
                                          tenantList.find(
                                              t =>
                                                  t.id ===
                                                  ((
                                                      selectedTenant.current as Tenant
                                                  )?.id || selectedTenants[0])
                                          )?.name!
                                      )
                                    : SYNC_TENANTS_WARNING
                            }
                        />
                    ) : (
                        <EditTenant
                            modalFields={modalFields}
                            setModalFields={setModalFields}
                        />
                    )}
                </Modal>
                <Menu
                    anchorEl={anchorElForActions}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    onClose={() => {
                        resetActionChanges();
                    }}
                    open={Boolean(anchorElForActions)}
                    PaperProps={
                        {
                            component: StyledMenuPaper,
                            sx: {
                                marginTop: '0px !important'
                            }
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {tenantActionOptions.map(action => (
                        <ProtectedAction
                            key={action.type}
                            hasAnyPermission={action.privileges}
                            resource={Resources.tenant}
                        >
                            <StyledMenuItem
                                key={action.type}
                                onClick={() => {
                                    setAnchorElForActions(null);
                                    setSelectedTenantAction(action.type);
                                    setModelOpen(true);

                                    const currentTenant =
                                        selectedTenant.current as Tenant;

                                    if (action.type === TenantActions.EDIT) {
                                        setModalFields(prevValue => ({
                                            ...prevValue,
                                            [TENANT_NAME]: {
                                                ...prevValue[TENANT_NAME],
                                                value: currentTenant.name
                                            },
                                            [TENANT_URL]: {
                                                ...prevValue[TENANT_URL],
                                                value: currentTenant.tenantUrl
                                            },
                                            [USERNAME]: {
                                                ...prevValue[USERNAME],
                                                value: currentTenant.username
                                            }
                                        }));
                                    }
                                }}
                            >
                                <IconButton>{action.icon}</IconButton>
                                <StyledOption>{action.label}</StyledOption>
                            </StyledMenuItem>
                        </ProtectedAction>
                    ))}
                </Menu>
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    context={{
                        isArmisUser
                    }}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onSelectionChanged={rowSelectionChangeHandler}
                    rowData={tenantList}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <span
                        style={{
                            display: isSyncing ? 'none' : ''
                        }}
                    >
                        <Pagination
                            ref={paginationRef}
                            onPaginationChanged={(page, size) => {
                                setQueryProperties(prevValue => ({
                                    ...prevValue,
                                    page,
                                    size
                                }));
                            }}
                            totalRowsCount={totalRows}
                        />
                    </span>
                )}
            </div>
        </>
    );
};

export const TenantManagement = IsLoadingHOC(TenantManagementComponent);
