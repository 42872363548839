import React, { useState, useRef, useEffect } from 'react';

import { Breadcrumbs, Tab, TabGroup } from '@armis/armis-ui-library';
import { Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import { POLICIES, TENANTS } from 'src/constants/LabelText';
import { TENANT_MANAGEMENT } from 'src/constants/RouteConstants';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { Header } from 'src/pages/components/Header';
import { Tenant } from 'src/pages/containers/TenantManagement/TenantManagement.types';
import { Policies } from 'src/pages/containers/TenantView/Policies/Policies';
import { getTenant } from 'src/services/api.service';

const allowedTabValue = {
    POLICY: 'policy',
    REPORT: 'report'
};

const tabs: Tab[] = [
    {
        label: POLICIES,
        tabValue: allowedTabValue.POLICY
    }
];

interface TenantViewProps {
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

const TenantViewComponent = ({ setIsLoading }: TenantViewProps) => {
    const { hash } = useLocation();
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState(
        hash ? hash.substring(1) : tabs[0].tabValue
    );
    const { tenantId } = useParams();
    const currentTenant = useRef<Tenant | null>(null);

    useEffect(() => {
        setIsLoading(true, true);
        getTenant(tenantId!)
            .then((res: AxiosResponse<Tenant>) => {
                currentTenant.current = res.data;
            })
            .catch(() => {
                navigate(`${TENANT_MANAGEMENT}`, { replace: true });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [tenantId, setIsLoading, navigate]);

    return (
        <>
            <Header>
                <Breadcrumbs>
                    <Link className="parent-link" to={TENANT_MANAGEMENT}>
                        {TENANTS}
                    </Link>
                    <Typography
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                        variant="h4"
                    >
                        {currentTenant.current?.name}
                    </Typography>
                </Breadcrumbs>
            </Header>
            <div>
                <TabGroup
                    onChange={(_, value) => {
                        setSelectedTab(value);
                        navigate({ hash: `#${value}` });
                    }}
                    style={{ marginLeft: '65px' }}
                    tabsItems={tabs}
                    value={selectedTab}
                />
            </div>
            {selectedTab === allowedTabValue.POLICY ? (
                <div className="control table" style={{ marginTop: '10px' }}>
                    <Policies
                        setIsLoading={setIsLoading}
                        tenantId={tenantId as string}
                    />
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export const TenantView = IsLoadingHOC(TenantViewComponent);
