import React, { useRef, useState, useMemo, useEffect } from 'react';

import {
    CHECKBOX_STATUS,
    CustomHeader,
    FileDiff,
    IconButton,
    MoreAction,
    MultiSelect,
    Pagination,
    Refresh,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    Template,
    Tooltip,
    View
} from '@armis/armis-ui-library';
import { Menu, MenuItem, PaperProps } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AxiosResponse } from 'axios';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    COMPARE_TEMPLATE,
    POLICIES,
    POLICY,
    PROMOTE_TO_TEMPLATE,
    REFRESH,
    SEARCH_POLICY_PLACEHOLDER,
    VIEW_JSON
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    tenantPolicyAPIMapping
} from 'src/constants/TableConstants';
import { tenantPolicyColumnsConfig } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties
} from 'src/helpers/utility';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { StyledOption } from 'src/pages/containers/TenantView/Policies/Policies.style';
import {
    AvailablePolicyActions,
    PoliciesProps,
    Policy
} from 'src/pages/containers/TenantView/Policies/Policies.types';
import { PolicyActions } from 'src/pages/containers/TenantView/Policies/PolicyActions/PolicyActions';
import { getTags, getTenantPolicies } from 'src/services/api.service';
import { GenericResponseData } from 'src/types/APIResponseTypes';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

const policySortOrder: Map<number> = {};
const policySortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const columnFilterObj: { [key: string]: FilterItems[] } = {
    tags: []
};
const queryPropertiesObj: { [key: string]: string } = {
    tags: ''
};
createRelatedObject(
    tenantPolicyAPIMapping,
    policySortOrder,
    policySortStatus,
    columnsFilterItems
);

const policyActionOptions = [
    {
        type: AvailablePolicyActions.PROMOTE,
        icon: <Template />,
        label: PROMOTE_TO_TEMPLATE,
        privileges: [Privileges.create]
    },
    {
        type: AvailablePolicyActions.VIEW_DIFF,
        icon: <FileDiff />,
        label: COMPARE_TEMPLATE,
        privileges: [Privileges.edit, Privileges.push]
    },
    {
        type: AvailablePolicyActions.VIEW_JSON,
        icon: <View />,
        label: VIEW_JSON,
        privileges: [Privileges.read]
    }
];

export const Policies = ({ tenantId, setIsLoading }: PoliciesProps) => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        queryProperties,
        setQueryProperties,
        onFilterChanged,
        columnFilterData,
        setColumnFilterData
    } = useTable({
        sortOrderObj: policySortOrder,
        sortStatusObj: policySortStatus,
        columnsFilterItems,
        queryPropertiesObj,
        columnFilterObj
    });
    const [policyList, setPolicyList] = useState<Policy[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [anchorElForPolicyOption, setAnchorElForPolicyOption] =
        useState<null | HTMLElement>(null);
    const [isPolicyActionsModalOpen, setIsPolicyActionsModalOpen] =
        useState(false);
    const [shouldRefresh, setShouldRefresh] = useState<{
        refresh: boolean;
        refreshFilterData: boolean;
    }>({ refresh: false, refreshFilterData: false });

    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);
    const selectedPolicy = useRef({
        id: '',
        AMPManaged: false,
        isModified: false,
        policyActionType: ''
    });

    const columnConfig = useMemo(
        () => [
            ...tenantPolicyColumnsConfig,
            {
                field: 'policyActions',
                headerName: 'Actions',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                pinned: 'right',
                width: 85,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (params: ICellRendererParams<Policy>) => (
                    <IconButton
                        className="Icon-Hover-Effect"
                        data-testid="policy-actions"
                        onClick={e => {
                            setAnchorElForPolicyOption(e.currentTarget);
                            selectedPolicy.current.id = params.data?.id!;
                            selectedPolicy.current.isModified =
                                !!params.data?.isModified;

                            // As tags are included in only AMP Managed Policies.
                            if (params.data?.tags?.length)
                                selectedPolicy.current.AMPManaged = true;
                            else selectedPolicy.current.AMPManaged = false;
                        }}
                        size="small"
                    >
                        <MoreAction />
                    </IconButton>
                ),
                sortable: false
            } as ColDef
        ],
        []
    );

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                onFilterChanged: (
                    columnName: string,
                    selectedItems: FilterItems[]
                ) => {
                    onFilterChanged(columnName, selectedItems);
                    paginationRef.current?.resetPagination();
                },
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true,
            filter: false,
            filterParams: columnFilterData
        }),
        [
            columnSortOrder,
            onSortChangedCall,
            columnFilterData,
            onFilterChanged,
            columnSortStatus
        ]
    );

    useEffect(() => {
        setTableLoading(true);
        const callAPIsSynchronously = async () => {
            try {
                if (
                    columnFilterData.tags.length === 0 ||
                    shouldRefresh.refreshFilterData
                ) {
                    const tagsResponse = await getTags();
                    setShouldRefresh(prevValue => ({
                        ...prevValue,
                        refreshFilterData: false
                    }));
                    // Preserve old filtered tags while adding new tags if found.
                    setColumnFilterData(prevValue => {
                        const oldTags = prevValue.tags;
                        let newTagsStatus: string = CHECKBOX_STATUS.CHECKED;
                        if (queryProperties.tags.toString().length !== 0) {
                            newTagsStatus = CHECKBOX_STATUS.UNCHECKED;
                        }
                        return {
                            ...prevValue,
                            tags: tagsResponse.data.content.map(
                                ({ id, name }: any) => ({
                                    id,
                                    label: name,
                                    labelRender: <span>{name}</span>,
                                    checkStatus:
                                        oldTags.find(tag => tag.id === id)
                                            ?.checkStatus ?? newTagsStatus
                                })
                            )
                        };
                    });
                }
                const tenantsResponse: AxiosResponse<
                    GenericResponseData<Policy>
                > = await getTenantPolicies(
                    tenantId,
                    convertQueryObjectToParams(queryProperties)
                );
                setPolicyList(tenantsResponse.data.content);
                setTotalRows(tenantsResponse.data.totalElements);
            } catch (err: any) {
                displayErrorMessage(err);
                setPolicyList([]);
                setTotalRows(0);
            } finally {
                setTableLoading(false);
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            }
        };

        callAPIsSynchronously();
    }, [
        queryProperties,
        tenantId,
        setTableLoading,
        setColumnFilterData,
        shouldRefresh.refresh
    ]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (policyList.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [policyList, tableLoading, gridRef]);

    const searchPolicyContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue =>
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }))
                    }
                    placeholder={SEARCH_POLICY_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy, setQueryProperties]
    );

    const getDisableActionStatus = (actionType: string) => {
        switch (actionType) {
            case AvailablePolicyActions.VIEW_JSON:
                return false;
            case AvailablePolicyActions.PROMOTE:
                return selectedPolicy.current.AMPManaged;
            case AvailablePolicyActions.VIEW_DIFF:
                return !selectedPolicy.current.isModified;
            default:
                return true;
        }
    };

    return (
        <>
            <PolicyActions
                actionType={selectedPolicy.current.policyActionType}
                modalOpen={isPolicyActionsModalOpen}
                onActionSuccess={() => {
                    setShouldRefresh(prevValue => ({
                        refresh: !prevValue.refresh,
                        refreshFilterData: true
                    }));
                }}
                onModalClose={() => {
                    selectedPolicy.current.policyActionType = '';
                    setIsPolicyActionsModalOpen(false);
                }}
                policy={policyList.find(
                    policy => policy.id === selectedPolicy.current.id
                )}
                setIsLoading={setIsLoading}
                tags={columnFilterData.tags?.map(tag => tag.label)}
            />
            <TableHeader
                childrenLeft={
                    <>
                        {searchPolicyContainer}
                        <Tooltip
                            arrow
                            placement="bottom"
                            sx={{
                                '& .MuiTooltip-tooltip': {
                                    minWidth: 'unset'
                                }
                            }}
                            title={
                                <div className="tooltip-arrow-text">
                                    {REFRESH}
                                </div>
                            }
                        >
                            <span>
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    color="primary"
                                    disabled={tableLoading}
                                    onClick={() => {
                                        setShouldRefresh(prevValue => ({
                                            refreshFilterData: true,
                                            refresh: !prevValue.refresh
                                        }));
                                    }}
                                >
                                    <Refresh />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                }
                loading={tableLoading}
                onColumnMenuClick={handleMenuClick}
                title={`${totalRows} ${
                    totalRows === 1 ? `${POLICY}` : `${POLICIES}`
                }`}
            />
            <Menu
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                open={Boolean(anchorEl)}
                PaperProps={
                    {
                        component: StyledMenuPaper
                    } as Partial<PaperProps<'div', {}>> | undefined
                }
            >
                <MultiSelect
                    items={filterItems}
                    onSelectionChanged={onSelectionChanged}
                    showSelectAllOption
                />
            </Menu>
            <Menu
                anchorEl={anchorElForPolicyOption}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                onClose={() => {
                    setAnchorElForPolicyOption(null);
                }}
                open={Boolean(anchorElForPolicyOption)}
                PaperProps={
                    {
                        component: StyledMenuPaper
                    } as Partial<PaperProps<'div', {}>> | undefined
                }
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {policyActionOptions.map(action => (
                    <ProtectedAction
                        key={action.type}
                        hasAnyPermission={action.privileges}
                        resource={Resources.policy}
                    >
                        <MenuItem
                            key={action.type}
                            disabled={getDisableActionStatus(action.type)}
                            onClick={() => {
                                setIsPolicyActionsModalOpen(true);
                                setAnchorElForPolicyOption(null);
                                selectedPolicy.current.policyActionType =
                                    action.type;
                            }}
                        >
                            {action.icon}
                            <StyledOption> {action.label} </StyledOption>
                        </MenuItem>
                    </ProtectedAction>
                ))}
            </Menu>
            <Table
                ref={gridRef}
                columnDefs={columnConfig}
                defaultColDef={defaultColDefs}
                loadingOverlayComponent={TableSkeleton}
                noRowsOverlayComponent={TableNoData}
                noRowsOverlayComponentParams={{
                    content: NO_DATA_TO_SHOW
                }}
                onFirstDataRendered={() => {}}
                rowData={policyList}
                suppressNoRowsOverlay={firstRender.current}
            />
            {!!totalRows && totalRows > 0 && (
                <Pagination
                    ref={paginationRef}
                    onPaginationChanged={(page, size) => {
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            page,
                            size
                        }));
                    }}
                    totalRowsCount={totalRows}
                />
            )}
        </>
    );
};
