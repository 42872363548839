import React, { useState, useRef, useMemo, useEffect } from 'react';

import {
    Button,
    Table,
    Pagination,
    Delete,
    TableSkeleton,
    TableNoData,
    CustomHeader,
    CHECKBOX_STATUS,
    StyledMenuPaper,
    MultiSelect,
    IconButton,
    Tooltip,
    Refresh,
    IconPencil,
    View,
    MoreAction,
    Push,
    IconDelete
} from '@armis/armis-ui-library';
import { PaperProps, Menu } from '@mui/material';
import {
    ColDef,
    ICellRendererParams,
    SelectionChangedEvent
} from 'ag-grid-community';
import { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    DELETE,
    EDIT,
    POLICIES,
    POLICY,
    PUSH_POLICY,
    REFRESH,
    SEARCH_POLICY_PLACEHOLDER,
    POLICY_TEMPLATES,
    VIEW_JSON,
    DELETE_POLICIES
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    templatePolicyAPIMapping
} from 'src/constants/TableConstants';
import { templatePolicyColumnConfig } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    isActionHasPermissions
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Header } from 'src/pages/components/Header';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { StyledActions } from 'src/pages/containers/PolicyTemplate/PolicyTemplate.style';
import { PolicyTemplateProps } from 'src/pages/containers/PolicyTemplate/PolicyTemplate.types';
import { StyledOption } from 'src/pages/containers/TenantView/Policies/Policies.style';
import { Policy } from 'src/pages/containers/TenantView/Policies/Policies.types';
import { getAllPolicyTemplates, getTags } from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import { GenericResponseData } from 'src/types/APIResponseTypes';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import { AvailableTemplatePolicyActions } from './constants';
import { PolicyTemplateActionModal } from './PolicyTemplateActionModal';
import { StyledMenuItem } from '../CustomProperties/CronEditField/CronEditField.style';

const templatePolicySortOrder: Map<number> = {};
const templatePolicySortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const columnFilterObj: { [key: string]: FilterItems[] } = {
    tags: []
};
const queryPropertiesObj: { [key: string]: string } = {
    tags: ''
};

createRelatedObject(
    templatePolicyAPIMapping,
    templatePolicySortOrder,
    templatePolicySortStatus,
    columnsFilterItems
);

const policyTemplateActionOptions = [
    {
        type: AvailableTemplatePolicyActions.DELETE,
        icon: <IconDelete />,
        label: DELETE,
        privileges: [Privileges.delete]
    },
    {
        type: AvailableTemplatePolicyActions.VIEW_JSON,
        icon: <View height={14} width={14} />,
        label: VIEW_JSON,
        privileges: [Privileges.read]
    }
];

const PolicyTemplateComponent = ({ setIsLoading }: PolicyTemplateProps) => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        modelOpen,
        setModelOpen,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        queryProperties,
        setQueryProperties,
        onFilterChanged,
        columnFilterData,
        setColumnFilterData
    } = useTable({
        sortOrderObj: templatePolicySortOrder,
        sortStatusObj: templatePolicySortStatus,
        columnsFilterItems,
        queryPropertiesObj,
        columnFilterObj
    });

    const [policyList, setPolicyList] = useState<Policy[]>([]);
    const [selectedPolicies, setSelectedPolicies] = useState<Policy[]>([]);
    const [totalRows, setTotalRows] = useState<null | number>(0);
    const [shouldRefresh, setShouldRefresh] = useState<{
        refresh: boolean;
        refreshFilterData: boolean;
    }>({ refresh: false, refreshFilterData: false });
    const [anchorElForPolicyActions, setanchorElForPolicyActions] =
        useState<null | HTMLElement>(null);
    const [selectedPolicyAction, setSelectedPolicyAction] = useState('');

    const selectedPolicy = useRef<Policy | null>(null);
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);

    const currentUser = useSelector(selectUser);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (policyList.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [policyList, tableLoading, gridRef]);

    useEffect(() => {
        setTableLoading(true);
        const callAPIsSynchronously = async () => {
            try {
                if (
                    columnFilterData.tags.length === 0 ||
                    shouldRefresh.refreshFilterData
                ) {
                    const tagsResponse = await getTags();
                    setShouldRefresh(prevValue => ({
                        ...prevValue,
                        refreshFilterData: false
                    }));

                    // Preserve old filtered tags while adding new tags if found.
                    setColumnFilterData(prevValue => {
                        const oldTags = prevValue.tags;
                        let newTagsStatus: string = CHECKBOX_STATUS.CHECKED;
                        if (queryProperties.tags.toString().length !== 0) {
                            newTagsStatus = CHECKBOX_STATUS.UNCHECKED;
                        }
                        return {
                            ...prevValue,
                            tags: tagsResponse.data.content.map(
                                ({ id, name }: any) => ({
                                    id,
                                    label: name,
                                    labelRender: <span>{name}</span>,
                                    checkStatus:
                                        oldTags.find(tag => tag.id === id)
                                            ?.checkStatus ?? newTagsStatus
                                })
                            )
                        };
                    });
                }
                const templatePolicyResponse: AxiosResponse<
                    GenericResponseData<Policy>
                > = await getAllPolicyTemplates(
                    convertQueryObjectToParams(queryProperties)
                );
                setPolicyList(templatePolicyResponse.data.content);
                setTotalRows(templatePolicyResponse.data.totalElements);
            } catch (err: any) {
                displayErrorMessage(err);
                setPolicyList([]);
                setTotalRows(0);
            } finally {
                setTableLoading(false);
                setSelectedPolicies([]);
                selectedPolicy.current = null;
                gridRef.current?.api.deselectAll();
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            }
        };

        callAPIsSynchronously();
    }, [
        queryProperties,
        setTableLoading,
        setColumnFilterData,
        shouldRefresh.refresh
    ]);

    const searchTenantContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue => {
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }));
                    }}
                    placeholder={SEARCH_POLICY_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy, setQueryProperties]
    );

    const columnConfig = useMemo(() => {
        let columnInfo = cloneDeep(templatePolicyColumnConfig);
        const hasAccess = isActionHasPermissions(
            currentUser,
            Resources.policy,
            [Privileges.delete, Privileges.push]
        );
        if (!hasAccess) columnInfo = cloneDeep(columnInfo).slice(1);
        return [
            ...columnInfo,
            {
                field: 'templatePolicyActions',
                headerName: 'Actions',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                pinned: 'right',
                width: 120,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (params: ICellRendererParams<Policy>) => (
                    <StyledActions>
                        <Tooltip
                            arrow
                            placement="bottom"
                            sx={{
                                '.MuiTooltip-tooltip': {
                                    marginTop: '4px !important'
                                }
                            }}
                            title={
                                <div className="tooltip-arrow-text">{EDIT}</div>
                            }
                        >
                            <IconButton
                                className="Icon-Hover-Effect"
                                data-testid="template-edit-action"
                                disabled={
                                    !isActionHasPermissions(
                                        currentUser,
                                        Resources.policy,
                                        [Privileges.edit]
                                    )
                                }
                                onClick={() => {
                                    setModelOpen(true);
                                    setSelectedPolicyAction(
                                        AvailableTemplatePolicyActions.EDIT
                                    );
                                    selectedPolicy.current = params.data!;
                                }}
                                size="small"
                            >
                                <IconPencil height={16} width={16} />
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            className="Icon-Hover-Effect"
                            data-testid="template-actions"
                            disabled={
                                !isActionHasPermissions(
                                    currentUser,
                                    Resources.policy,
                                    [Privileges.delete, Privileges.read]
                                )
                            }
                            onClick={e => {
                                setanchorElForPolicyActions(e.currentTarget);
                                selectedPolicy.current = params.data!;
                            }}
                            size="small"
                        >
                            <MoreAction />
                        </IconButton>
                    </StyledActions>
                ),
                sortable: false
            } as ColDef
        ];
    }, []);

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                onFilterChanged: (
                    columnName: string,
                    selectedItems: FilterItems[]
                ) => {
                    onFilterChanged(columnName, selectedItems);
                    paginationRef.current?.resetPagination();
                },
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true,
            filter: false,
            filterParams: columnFilterData
        }),
        [
            columnSortOrder,
            onSortChangedCall,
            columnFilterData,
            onFilterChanged,
            columnSortStatus
        ]
    );

    const rowSelectionChangeHandler = (
        event: SelectionChangedEvent<Policy>
    ) => {
        setSelectedPolicies(event.api.getSelectedRows());
    };

    const onClosePolicyActionMenu = () => {
        setanchorElForPolicyActions(null);
    };

    const onClosePolicyActionModal = () => {
        setModelOpen(false);
        setSelectedPolicyAction('');
        selectedPolicy.current = null;
    };

    return (
        <>
            <Header title={POLICY_TEMPLATES} />
            <div className="control table">
                {modelOpen && (
                    <PolicyTemplateActionModal
                        modalOpen={modelOpen}
                        onClose={onClosePolicyActionModal}
                        onSuccess={() => {
                            onClosePolicyActionModal();
                            setShouldRefresh(prevValue => ({
                                ...prevValue,
                                refresh: !prevValue.refresh
                            }));
                        }}
                        selectedAction={selectedPolicyAction}
                        selectedPolicies={
                            selectedPolicy.current
                                ? [selectedPolicy.current]
                                : selectedPolicies
                        }
                        setIsLoading={setIsLoading}
                    />
                )}
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Menu
                    anchorEl={anchorElForPolicyActions}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    onClose={onClosePolicyActionMenu}
                    open={Boolean(anchorElForPolicyActions)}
                    PaperProps={
                        {
                            component: StyledMenuPaper,
                            sx: {
                                marginTop: '0px !important'
                            }
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {policyTemplateActionOptions.map(action => (
                        <ProtectedAction
                            key={action.type}
                            hasAnyPermission={action.privileges}
                            resource={Resources.policy}
                        >
                            <StyledMenuItem
                                key={action.type}
                                onClick={() => {
                                    onClosePolicyActionMenu();
                                    setSelectedPolicyAction(action.type);
                                    setModelOpen(true);
                                }}
                            >
                                {action.icon}
                                <StyledOption> {action.label} </StyledOption>
                            </StyledMenuItem>
                        </ProtectedAction>
                    ))}
                </Menu>
                <TableHeader
                    childrenLeft={
                        <>
                            {searchTenantContainer}
                            <div>
                                <ProtectedAction
                                    hasPermissions={[Privileges.push]}
                                    resource={Resources.policy}
                                >
                                    <Button
                                        color="primary"
                                        disabled={
                                            !selectedPolicies.length ||
                                            tableLoading
                                        }
                                        onClick={() => {
                                            setSelectedPolicyAction(
                                                AvailableTemplatePolicyActions.PUSH_POLICY
                                            );
                                            setModelOpen(true);
                                        }}
                                        startIcon={<Push />}
                                        variant="text"
                                        // sx={{ padding: 0 }}
                                    >
                                        {PUSH_POLICY}
                                    </Button>
                                </ProtectedAction>
                                <ProtectedAction
                                    hasPermissions={[Privileges.delete]}
                                    resource={Resources.policy}
                                >
                                    <Button
                                        color="primary"
                                        disabled={
                                            !selectedPolicies.length ||
                                            tableLoading
                                        }
                                        onClick={() => {
                                            selectedPolicy.current = null;
                                            setSelectedPolicyAction(
                                                AvailableTemplatePolicyActions.DELETE
                                            );
                                            setModelOpen(true);
                                        }}
                                        startIcon={<Delete />}
                                        variant="text"
                                        // sx={{ padding: 0 }}
                                    >
                                        {DELETE_POLICIES}
                                    </Button>
                                </ProtectedAction>
                            </div>
                        </>
                    }
                    childrenRight={
                        <Tooltip
                            arrow
                            placement="bottom"
                            title={
                                <div className="tooltip-arrow-text">
                                    {REFRESH}
                                </div>
                            }
                        >
                            <IconButton
                                className="Icon-Hover-Effect"
                                color="primary"
                                disabled={tableLoading}
                                onClick={() => {
                                    setShouldRefresh(prevValue => ({
                                        refreshFilterData: true,
                                        refresh: !prevValue.refresh
                                    }));
                                }}
                            >
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    }
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedPolicies.length}
                    title={`${totalRows} ${
                        totalRows === 1 ? `${POLICY}` : `${POLICIES}`
                    }`}
                />
                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onFirstDataRendered={() => {}}
                    onSelectionChanged={rowSelectionChangeHandler}
                    rowData={policyList}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </>
    );
};

export const PolicyTemplate = IsLoadingHOC(PolicyTemplateComponent);
